import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { useEffect } from 'react'
import { tokens } from '../../../../theme'
import Line from '../line'
import StatBoxMoves from '../../components/StatBoxMoves'
import {
  getStatsLineLastDay,
  getStatsLineLastHour,
  getStatsLineLastMonth,
  getStatsLineLastSevenDays,
} from '../../services/services'
import Header from '../../components/Header'
import TableMovements from '../MovimientosDashboard/components/TableMovements/TableMovements'
import { useDispatch, useSelector } from 'react-redux'
import { getDataMovimientosThunk } from '../../redux/slices/movimientosSlice/thunk/getDataMovimientosThunk'
import { texts } from '../../../../i18n'
import Bar from '../bar'

const MovimientosDashboard = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const data = useSelector((state) => state.movimientosData.data)
  let newData = [...data.map(obj => ({ ...obj }))];
  newData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDataMovimientosThunk())
    return () => {} // eslint-disable-next-line
  }, [])

  const colors = tokens(warehouse.dark)
  const [hourCount, setHourCount] = useState({})
  const [dayCount, setDayCount] = useState({})
  const [weekCount, setWeekCount] = useState({})
  const [monthCount, setMonthCount] = useState({})

  useEffect(() => {
    async function cargarDatos() {
      const hour = await getStatsLineLastHour()
      const day = await getStatsLineLastDay()
      const week = await getStatsLineLastSevenDays()
      const month = await getStatsLineLastMonth()
      setHourCount(hour)
      setDayCount(day)
      setWeekCount(week)
      setMonthCount(month)
    }

    cargarDatos()
  }, [])

  return (
    <Box m='20px' sx={{ width: '100%' }}>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap='20px'>
        <Box gridColumn='span 12' display='flex'>
          <Header title={texts[lang]['TITLE_MOV']} subtitle={texts[lang]['SUBT_MOV']} />
        </Box>
        {/* ROW 1 */}
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='140px'
        >
          <StatBoxMoves
            titulo={texts[lang]['TITLE_ACTIV_H']}
            total={hourCount['total'] || 0}
            entradas={hourCount['entradas'] || 0}
            salidas={hourCount['salidas'] || 0}
            reub={hourCount['reubicaciones'] || 0}
            rechazos={hourCount['rechazos'] || 0}
            hoverText={texts[lang]['H_TITLE_ACTIV_H']}
          />
        </Box>
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <StatBoxMoves
            titulo={texts[lang]['TITLE_ACTIV_24']}
            total={dayCount['total'] || 0}
            entradas={dayCount['entradas'] || 0}
            salidas={dayCount['salidas'] || 0}
            reub={dayCount['reubicaciones'] || 0}
            rechazos={dayCount['rechazos'] || 0}
            hoverText={texts[lang]['H_TITLE_ACTIV_24']}
          />
        </Box>
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <StatBoxMoves
            titulo={texts[lang]['TITLE_ACTIV_W']}
            total={weekCount['total'] || 0}
            entradas={weekCount['entradas'] || 0}
            salidas={weekCount['salidas'] || 0}
            reub={weekCount['reubicaciones'] || 0}
            rechazos={weekCount['rechazos'] || 0}
            hoverText={texts[lang]['H_TITLE_ACTIV_W']}
          />
        </Box>
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <StatBoxMoves
            titulo={texts[lang]['TITLE_ACTIV_M']}
            total={monthCount['total'] || 0}
            entradas={monthCount['entradas'] || 0}
            salidas={monthCount['salidas'] || 0}
            reub={monthCount['reubicaciones'] || 0}
            rechazos={monthCount['rechazos'] || 0}
            hoverText={texts[lang]['H_TITLE_ACTIV_M']}
          />
        </Box>
        {/* ROW 2 */}

        <Box gridColumn='span 6' backgroundColor={colors.primary[400]} justifyContent='center'>
          <Bar tipo='dia' />
        </Box>

        <Box gridColumn='span 6' backgroundColor={colors.primary[400]} justifyContent='center'>
          <Bar tipo='semana' />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn='span 12'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Box m='25px' width='95%'>
            <Header title={texts[lang]['TITLE_MOV_TABLE']} subtitle={texts[lang]['SUBT_MOV_TABLE']} />
            <TableMovements data={newData} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MovimientosDashboard
