import React, { useContext, useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { ContextoData } from '../../../../../../contexts/Data/DataContext'
import { translate_table } from '../../../../../Almacen/components/Busqueda/Translate'
import { ContextoAlmacen } from '../../../../../../contexts/Almacen/AlmacenContext'
import ExportButtons from '../../../../../../components/ExportButtons/ExportButtons'
import { tokens } from '../../../../../../theme'

const TableMovements = ({ data }) => {
  const { data_format_movements } = useContext(ContextoData)
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  const columns = useMemo(() => [...data_format_movements], [data_format_movements])
  // ------------ VISIBILITY COLUMN SAVE ---------------------------
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('movements_column_visibility')
      ? JSON.parse(localStorage.getItem('movements_column_visibility'))
      : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  useEffect(() => {
    localStorage.setItem('movements_column_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])
  // ------------------------- ******************** ---------------------
  return (
    <MaterialReactTable
      globalFilterFn='contains'
      enableFilterMatchHighlighting
      columns={columns}
      data={data || []}
      localization={translate_table}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{ sx: { backgroundColor: colors.primary[400]} }}
      muiToolbarAlertBannerProps={{
        sx: { color: "#F9F9F9", backgroundColor: "#47817F"},
      }}
      muiTableHeadCellProps ={{ sx: { backgroundColor: colors.primary[400]} }}
      initialState={{
        density: 'compact',
        //sorting: [{ id: 'id', desc: true }],
       
        expanded: false,
        pagination: {
          pageIndex: 0,
          pageSize: 100,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      state={{ columnVisibility }}
      onColumnVisibilityChange={setColumnVisibility}
      enableGrouping
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      renderTopToolbarCustomActions={({ table }) => {
        const filters = table?.getAllColumns()?.reduce((acc,elm) => elm.getFilterValue() ? ({...acc, [elm.id]: elm.getFilterValue()}) : acc,{});
        return <ExportButtons filter={filters} data={table.getFilteredRowModel().rows} title='Movimientos' />
      }}
    />
  )
}

export default TableMovements
