import { createSlice } from "@reduxjs/toolkit";
import {
  getConfigCargaThunk,
  getConfigDescargaThunk,
} from "./thunk/getConfigCargaThunk";

// -------------- PROPIEDAES PARA CONFOMAR REGLAS DE AUTONONOMO --------------------------
const data_properties_autonomo = [
  {
    accessorKey: "sku",
    header: "SKU",
  },
  {
    accessorKey: "family",
    header: "Familia",
  },
  {
    accessorKey: "countryOfDest",
    header: "Pais de Destino",
  },
];

const configuracionAutonomoSlice = createSlice({
  name: "configuracion_autonomo",
  initialState: {
    request: {
      requestId: null,
      loading: "idle",
      error: null,
    },
    request_download: {
      requestId: null,
      loading: "idle",
      error: null,
    },
    active: [],
    inactive: [...data_properties_autonomo],
    ingreso: "deposito",
    date_props: {
      property: "expiration_date",
      value: 30,
    },
    download_config: "fefo",
    download_exit: "2",
  },
  reducers: {
    setValueDays(state, action) {
      state.date_props.value = action.payload;
    },
    setRight(state, action) {
      state.active = action.payload;
    },
    setLeft(state, action) {
      state.inactive = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Get and Set Positions Object
    builder.addCase(getConfigCargaThunk.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      const { result } = action.payload;
      if (
        state.request.loading === "pending" &&
        state.request.currentRequestId === requestId
      ) {
        state.request.loading = "idle";
        const activeItems = data_properties_autonomo.filter((items) =>
          result.map((list) => list.property).includes(items.accessorKey)
        );
        state.active = activeItems;
        const inactiveItems = data_properties_autonomo.filter(
          (items) =>
            !result.map((list) => list.property).includes(items.accessorKey)
        );
        state.inactive = inactiveItems;
        state.date_props = {
          ...state.date_props,
          value: result[0]?.days || 30,
        };
        state.ingreso = result[0]?.ingreso;
      }
    });
    builder.addCase(getConfigCargaThunk.pending, (state, action) => {
      if (state.request.loading === "idle") {
        state.request.loading = "pending";
        state.request.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(getConfigCargaThunk.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (
        state.request.loading === "pending" &&
        state.request.currentRequestId === requestId
      ) {
        state.request.loading = "idle";
        state.request.error = action.error;
        state.request.currentRequestId = undefined;
      }
    });

    // Autonomo descarga Thunk
    builder.addCase(getConfigDescargaThunk.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      const { result } = action.payload;
      if (
        state.request_download.loading === "pending" &&
        state.request_download.currentRequestId === requestId
      ) {
        state.request_download.loading = "idle";
        state.download_config = result[0]?.reubications
          ? "movimientos"
          : "fefo";
        state.download_exit = result[0]?.exit;
      }
    });
    builder.addCase(getConfigDescargaThunk.pending, (state, action) => {
      if (state.request_download.loading === "idle") {
        state.request_download.loading = "pending";
        state.request_download.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(getConfigDescargaThunk.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (
        state.request_download.loading === "pending" &&
        state.request_download.currentRequestId === requestId
      ) {
        state.request_download.loading = "idle";
        state.request_download.error = action.error;
        state.request_download.currentRequestId = undefined;
      }
    });
  },
});

export const { setValueDays, setLeft, setRight } =
  configuracionAutonomoSlice.actions;
export default configuracionAutonomoSlice.reducer;
