import React, { useContext, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { MdMenu } from "react-icons/md";
import styles from "./Menu.module.css";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { ContextoAlmacen } from "../../contexts/Almacen/AlmacenContext";
import { ThemeSwitch } from "../ThemeSwitch/ThemeSwtich";
import {
  EditRoad,
  FormatListNumbered,
  Psychology,
  Settings,
  TimelineOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { texts } from "../../i18n";

const Menu = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { warehouse, setTheme, setLang } = useContext(ContextoAlmacen);
  const { lang } = warehouse;

  return (
    <>
      <Tooltip title="Menú" arrow placement="left">
        <button onClick={handleShow} className={styles.boton}>
          <MdMenu
            className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`}
          />
        </button>
      </Tooltip>

      <Offcanvas
        show={show}
        backdrop={false}
        onHide={handleClose}
        placement="end"
        enforceFocus={false}
        className={styles.canvas}
      >
        <Offcanvas.Header closeVariant={"white"} closeButton>
          <Offcanvas.Title>
            <ThemeSwitch theme={warehouse.dark} changeTheme={setTheme} /> Menú
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.canvaBody}>
          <Stack
            justifyContent={"space-between"}
            sx={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
            }}
          >
            <div>
              <Link to="/" className={styles.canvaBoton}>
                <BsFillGrid3X3GapFill /> {texts[lang]["MENU_GRILLA"]}
              </Link>
              <Link to="/descarga" className={styles.canvaBoton}>
                <FormatListNumbered />
                Orden de Descarga
              </Link>
              <Link to="/dashboard" className={styles.canvaBoton}>
                <TimelineOutlined />
                {texts[lang]["MENU_DASH"]}
              </Link>
              <Link to="/admin-pos" className={styles.canvaBoton}>
                <EditRoad />
                {texts[lang]["MENU_ADM"]}
              </Link>
              <Link to="/machine" className={styles.canvaBoton}>
                <Settings />
                Machine Logs
              </Link>
              <Link to="/config" className={styles.canvaBoton}>
                <Psychology />
                Configuración Autonomo
              </Link>
            </div>
            <FormControl sx={{ padding: "1rem" }}>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{ padding: "1rem", paddingLeft: "2.5rem" }}
              >
                Lang
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Lang"
                onChange={(e) => setLang(e.target.value)}
                sx={{ width: "50%" }}
              >
                <MenuItem value={"ES"}>ES</MenuItem>
                <MenuItem value={"ENG"}>ENG</MenuItem>
                {/* <MenuItem value={'PT'}>PT</MenuItem> */}
              </Select>
            </FormControl>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Menu;
