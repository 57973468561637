const translate_status = {
  cancel: 'cancelado',
  null: 'en cola',
  transit: 'transito',
  complete: 'completado',
  waiting: 'espera',
  pending: 'pendiente',
  '': 'en cola',
};

class DownloadItem {
  constructor(position) {
    this.n_orden = position?.dl_order;
    this.prioridad_de_descarga = position?.lu_dl_order;
    this.estado = position.status
      ? translate_status[position.status]
      : 'en cola';
    this.data_origin = position?.data_origin;
    this.lpn = position?.lpn;
    this.sku = position?.sku;
    this.producto = position?.description;
    this.posicion = position?.slotId;
    this.cliente = position?.area;
    this.cantidad = position?.qty;
    this.vencimiento = position?.expiration;
  }
}

export const downloadAdapter = (list) => {
  const lista = list.result.map((l) => new DownloadItem(l));
  return lista;
};
