import { ResponsiveBar } from '@nivo/bar'
import { useEffect, useState } from 'react'
import { tokens } from '../../../theme'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { Box } from '@mui/material'
import { getStatsLineLastDays } from '../services/services'

const BarMovementsDays = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  const [datos, setDatos] = useState()
  const colorArr = [colors.verdes[500], colors.verdes[400], colors.verdes[300], colors.verdes[200], colors.verdes[100]]


  useEffect(() => {
    async function cargarDatos() {
      const data = await getStatsLineLastDays().catch(e => console.error(e))
      
      //Transformo la data
      const dataT = data
      ?.reduce((acc,elm) => ([
        ...acc, 
        ...elm.data.map(obj => ({...obj, categoria: elm.id})),
      ]),[])
      ?.reduce((acc, elm) => ({
        ...acc, 
        ...(acc[elm.x] ? {[elm.x]:[...acc[elm.x], elm]} : {[elm.x]:[elm]})
      }),{})
      const dataT2 = Object.keys(dataT || {}).map(key => ({categoria: key, ...dataT[key].reduce((acc,elm) => ({...acc, [elm.categoria]:elm.y}),{}) }))
      setDatos(dataT2 || [])
    }
    cargarDatos()
  }, [])


  return (
    <Box height='500px'>
      <ResponsiveBar
        data={datos || []}
        keys={['total','entradas','salidas','reubicaciones','rechazos']}
        groupMode='grouped' 
        indexBy='categoria'
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={colorArr}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        theme={{
          tooltip: {
            color: colors.grey[100],
            container: {
              background: colors.primary[900],
            },
          },
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'dias',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'cantidad',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role='application'
        ariaLabel='Nivo bar chart demo'
        barAriaLabel={function (e) {
          return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
        }}
      />
    </Box>
  )
}

export default BarMovementsDays
