import { useContext, useState } from 'react'
import { useEffect } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import Calle from '../Calle/Calle'
import Regla from '../Regla/Regla'
import { ReactComponent as Ctto } from '../../../../assets/imgs/Ctto_LOGO-WHITE.svg'
import InfoBox from '../InfoBox/InfoBox'
import LoadingCtto from '../../../../components/LoadingCtto/LoadingCtto'
import EditBox from '../EditBox/EditBox'
import useSocket from '../../../../custom-hooks/useSocket'
import { useDispatch, useSelector } from 'react-redux'
import { getPositionsThunk } from '../../redux/slices/positions/thunk/positionsThunk'
import { getConfirmOrdersThunk } from '../../../Descarga/redux/slices/lista/thunk/getConfirmOrdersThunk'
import { getNotificationsThunk } from '../../redux/slices/notifications/thunks/notificationsThunks'
import { setErrorToast } from '../../redux/slices/notifications/NotificationsSlice'

const Grilla = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const calles = [...Array(warehouse.calles)]
  const socket = useSocket()

  // --------------- Get Positions ---------------
  const dispatch = useDispatch()
  const posiciones = useSelector((state) => state.positions)
  const zoom = useSelector((state) => state.gridView.zoom)
  const [error, setError] = useState(false)

  // eslint-disable-next-line
  useEffect(() => {dispatch(getPositionsThunk())}, [])
  // ------------- CARGA Y ACTUALIZACION DEL DEPOSITO -----------------
  useEffect(() => {
    socket.on("state:actualizar-posiciones", async () => {
      try {
        //Actualiza la pagina cada vez que
        if (error) {
          window.location.reload();
        }

        dispatch(getPositionsThunk());
        dispatch(getConfirmOrdersThunk()); // actualiza el estado de las ordenes
      } catch (e) {
        console.log(e);
      }
    });
    return () => {
      socket.off("state:actualizar-posiciones");
    };
    // eslint-disable-next-line
  }, [socket, error]);

  //GET NOTIFICACIONES
  useEffect(()=>{
    dispatch(getNotificationsThunk())
    .then(res => {
      const notifiacionDeError = res?.payload?.find(elm => (elm.level === 'warning' || elm.level === 'error') && !elm.view)
      if(notifiacionDeError){
        dispatch(setErrorToast({msg: notifiacionDeError.message, clase: notifiacionDeError.level, display: true}))
        setTimeout(() => dispatch(setErrorToast()), 10000);
      }
    })
    .catch(e => console.log(e))

    // eslint-disable-next-line
  },[])

  if (!posiciones.positions || posiciones.error) {
    if (posiciones.loading === 'pending' || posiciones.error) {
      if (posiciones.error) {
        if (!error) {
          setError(true);
        }
      }
      return (
        <LoadingCtto loading={true} error={posiciones.error}>
          <Ctto />
        </LoadingCtto>
      )
    }
  }
  // ----------------------- ************ -----------------------
  return (
    <div
      style={{
        transform: `scale(${zoom})`,
        transformOrigin: 'top left',
        padding: '2rem',
        width: 'fit-content',
        height: 'inherit',
      }}
    >
      <div style={{ display: 'flex', gap: '2rem', padding: '1rem' }}>
        {calles.map((_, i) => (
          <Calle
            key={i}
            info_box='ID'
            calle={warehouse.orientacion === 'derecha' ? calles.length - i : i + 1}
            lado={warehouse.orientacion === 'derecha' ? 1 : 0}
          />
        ))}
      </div>

      <Regla />

      <div style={{ display: 'flex', gap: '2rem', padding: '1rem' }}>
        {calles.map((_, i) => (
          <Calle
            key={i}
            info_box='ID'
            calle={warehouse.orientacion === 'derecha' ? calles.length - i : i + 1}
            lado={warehouse.orientacion === 'derecha' ? 0 : 1}
          />
        ))}
      </div>
      <InfoBox />
      <EditBox />
    </div>
  )
}

export default Grilla
