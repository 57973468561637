import { useEffect, useState } from 'react'
import useColor from './custom-hooks/useColor'
import { BoxDraw } from './Box.styled'
import useAsequiblePosition from './custom-hooks/useAsequiblePosition'
import { Snackbar, Alert } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setEdit, setInfo } from '../../redux/slices/positionClickSlice/positionClickSlice'
import { setDestino, setOrigen } from '../../redux/slices/operation/operationSlice'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { DownloadingOutlined } from '@mui/icons-material'

const Box = ({ _id }) => {
  const posiciones = useSelector((state) => state.positions.positions)

  const listaConfirmadas = useSelector((state) => state.lista_descarga.confirm_orders.lista)
  const { lista: listaDescarga } = useSelector((state) => state.lista_descarga);

  const nivel_actual = useSelector((state) => state.gridView.actualLevel)
  const operacion = useSelector((state) => state.operation)
  const { warehouse } = useContext(ContextoAlmacen)
  const { origen, destino } = operacion

  const filtro = useSelector((state) => state.filter.filter)
  const { color, days } = useColor(_id, posiciones, nivel_actual, filtro) // Custom hook set color box

  const dispatch = useDispatch()

  const [hover, setHover] = useState(false)
  const [viewpop, setViewPop] = useState(false)
  const [inList, setInList] = useState(false)
  const [confirmada, setConfirmada] = useState(false)
  const [waiting, setWaiting] = useState(false)

  const { asequible } = useAsequiblePosition({ posiciones, _id, origen, nivel_actual })

  const handleClick = (event) => {
    if (event.shiftKey) {
      if (posiciones[_id]?.estado === 3 || posiciones[_id]?.estado === 2) return
      if (!asequible) {
        setViewPop(true)
        return
      }
      posiciones[_id]?.estado === 1 ? dispatch(setOrigen(_id)) : dispatch(setDestino(_id))
      return
    }
    if (event.ctrlKey) {
      dispatch(setEdit(posiciones[_id] ? posiciones[_id] : { id: _id }))
      return
    }

    dispatch(setInfo(posiciones[_id] ? posiciones[_id] : { id: _id }))
  }

  const isHide = (id) => {
    const excludePositions = warehouse.exclude
    if (excludePositions.includes(id)) return 'hidden'
    return ''
  }

  useEffect(() => {
    let inList = false;
    let confirmada = false;
    let waiting = false;

    const checkConfirm = () => {
      listaConfirmadas.forEach((lc) => {
        if (lc.posicion === _id) {
          inList = true;
          if (lc.estado === "espera") {
            waiting = true;
          } else {
            confirmada = true;
          }
        }
        return;
      });
    };

    const checkDownload = () => {
      listaDescarga.forEach((ld) => {
        if (ld.id === _id) {
          inList = true;
        }
        return;
      });
    };
    checkConfirm();
    checkDownload();

    setConfirmada(confirmada);
    setWaiting(waiting);
    setInList(inList);
    //eslint-disable-next-line
  }, [listaConfirmadas, listaDescarga, nivel_actual]);

  return (
    <div style={{ backgroundColor: 'white', visibility: isHide(_id) }}>
      <BoxDraw
        color={color}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
        selected={(_id === origen || _id === destino) && true}
        inList={inList}
        waiting = {waiting}
      >

        {((!hover && confirmada && !days) || (!hover && waiting && !days)) && <span style={{fontSize:"15px", position:"absolute"}}>
          <DownloadingOutlined/>
        </span>}

        <span style={{ userSelect: 'none' }}>{hover ? _id.slice(-2) : days}</span>
      </BoxDraw>
      <Snackbar
        open={viewpop}
        autoHideDuration={5000}
        onClose={() => setViewPop(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='warning' sx={{ width: '100%', userSelect: 'none' }}>
          Posicion Obstruida, necesita reubicación
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Box
