import * as React from 'react'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { useDispatch } from 'react-redux'
import { zoomIn, zoomNone, zoomOut } from '../../redux/slices/gridView/gridViewSlice'

export default function BotonDesplegable() {
  const { transRef } = useContext(ContextoAlmacen)
  const dispatch = useDispatch()

  const actions = [
    { icon: <ZoomOutMapIcon />, name: 'Restablecer', action: () => dispatch(zoomNone()) },
    { icon: <RemoveCircleOutlineIcon />, name: 'Zoom -', action: () => dispatch(zoomOut(0.1)) },
    { icon: <AddCircleOutlineIcon />, name: 'Zoom +', action: () => dispatch(zoomIn(0.1)) },
    {
      icon: <LocationSearchingIcon />,
      name: 'Ubicar',
      action: () => transRef.current.scrollIntoView({ behavior: 'smooth', block:'center', inline:'center' }),
    },
  ]

  return (
    <SpeedDial
      ariaLabel='SpeedDial basic example'
      sx={{ position: 'absolute', right: 0, bottom: 'calc((5vh - 30px) / 2)' }}
      icon={<ExpandLessIcon />}
      color='custom'
      variant='contained'
      FabProps={{
        sx: {
          bgcolor: '#46afaf6b',
          width: '30px',
          height: '30px',
          minHeight: '30px',
          '&:hover': { bgcolor: '#495a74' },
        },
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.action} />
      ))}
    </SpeedDial>
  )
}
