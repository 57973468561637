import { getTransferList } from "../pages/Configuracion/services/services"
import decodeID from "./decodeId"

const sortLCNandProf = (array) => {
    const sortedArray = array.sort((a, b) => {
      const { lado: la, nivel: na, calle: ca, prof: pa } = decodeID(a.id)
      const { lado: lb, nivel: nb, calle: cb, prof: pb } = decodeID(b.id)
      if (la === lb && na === nb && ca === cb) return pa - pb
      return 0
    })
    return sortedArray
  }

  const existsInTransferList = async(id) => {
    const {result} = await getTransferList()
    if (result?.some((transfer) => transfer.slot_id_origin === id)){
      return true
    } 
    return false
  }

  export const listToTransfer = async(id, profundidades, destino, posiciones) =>{
    const decode = decodeID(id)
    const lista = sortLCNandProf(filterStreet(`L${decode.lado}C${decode.calle}N${decode.nivel}`, posiciones))
    let { lado, calle, nivel, prof } = decodeID(destino)
    let aux = prof
    let listToTransfer = await Promise.all(lista.map(async(p) => {
      if (aux === 0) return {}
      const {prof} = decodeID(p.id);
      //Si la posicion de origen ya existe en transferencias, se omite
      if (await existsInTransferList(p.id)) return{}
      if (prof > profundidades) return{}

      let ob = {
        slot_id_origin: p.id,
        slot_id_destiny: `L${lado}C${calle}N${nivel}P${aux}`,
      }

      aux--
      return ob
    }))

    listToTransfer = listToTransfer.filter((l) => l.slot_id_destiny && l.slot_id_origin)
    return listToTransfer;
  }

  export const filterStreet = (id, posiciones) => {
    const regex = new RegExp(`^${id}P[1-5]\\b`);
    const arr = Object.values(posiciones).filter((p) => regex.test(p?.id) && +p.estado === 1)
    return arr
  }