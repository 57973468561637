import { downloadAdapter } from '../adapters/adapters';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// +++++++++++++++++++++++++ POST ORDER  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const postDownloadOrder = async (list) => {
  try {
    const response = await fetch(SERVER_URL + '/download/', {
      method: 'POST',
      body: JSON.stringify(list),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return await response.json();
  } catch (e) {
    throw Error('Error no se puede publicar la orden');
  }
};

// +++++++++++++++++++++++++ GET ORDERS CONFIRM  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getDownloadOrdersConfirm = async () => {
  try {
    const response = await fetch(SERVER_URL + '/download/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    return downloadAdapter(result);
  } catch (e) {
    throw Error('Error al obtener ordenes');
  }
};

// +++++++++++++++++++++++++ UPDATE STATE ORDERS CONFIRM  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const updateDownloadOrdersConfirm = async (list) => {
  try {
    const response = await fetch(SERVER_URL + '/download/', {
      method: 'PUT',
      body: JSON.stringify(list),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error('Error al cancelar Ordenes');
  }
};

// +++++++++++++++++++++++++ GET ORDERS CONFIRM HISTORY ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getDownloadOrdersHistory = async () => {
  try {
    const response = await fetch(SERVER_URL + '/download/history/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    return downloadAdapter(result);
  } catch (e) {
    throw Error('Error al obtener ordenes');
  }
};

// +++++++++++++++++++++++++ GET ORDERS CONFIRM HISTORY ++++++++++++++++++++++++++++++++++++++++++++++++++
export const createListBySKU = async (skuList) => {
  try {
    const response = await fetch(SERVER_URL + '/download/sku', {
      method: 'POST',
      body: JSON.stringify({ skuList }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error('Error al obtener pallets disponibles');
  }
};
