import { Box } from '@mui/material'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'
import BarSlots from '../../components/BarSlots'
import Header from '../../components/Header'
import BarMovementsHours from '../../components/BarMovementsHours'
import BarMovementsDays from '../../components/BarMovementsDays'

const Bar = ({tipo}) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  if(tipo === 'semana'){
    return (
      <Box m='20px'>
        <Header title={texts[lang]['TITLE_GRAP_M_D']} subtitle={texts[lang]['SUBT_GRAP_M_D']} />
        <BarMovementsDays />
      </Box>
    )
  }

  if(tipo === 'dia'){
    return (
      <Box m='20px'>
        <Header title={texts[lang]['TITLE_GRAP_M_H']} subtitle={texts[lang]['SUBT_GRAP_M_H']} />
        <BarMovementsHours />
      </Box>
    )
  }

  return (
    <Box m='20px'>
      <Header title={texts[lang]['TITLE_GRAP_C_L']} subtitle={texts[lang]['SUBT_GRAP_C_L']} />
      <BarSlots />
    </Box>
  )
}

export default Bar
