import React, { useContext, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ButtonSpinner from "../../../../../components/ButtonSpinner/ButtonSpinner";
import ErrorOrSuccessMsg from "../../../../../components/ErrorOrSuccessMsg/ErrorOrSuccesMsg";
import { ContextoAlmacen } from "../../../../../contexts/Almacen/AlmacenContext";
import { texts } from "../../../../../i18n";
import { getPalletsThunk } from "../../../../AdminPosiciones/redux/slices/configuracion/thunk/getPalletsThunk";
import { closeEditBox } from "../../../redux/slices/positionClickSlice/positionClickSlice";
import { getPositionsThunk } from "../../../redux/slices/positions/thunk/positionsThunk";
import { putSlot } from "../../../services/services";
import styles from "./EditPosicion.module.css";

const EditEstado = ({ ...data }) => {
  const { warehouse } = useContext(ContextoAlmacen);
  const { lang } = warehouse;
  const [errorProps, setErrorProps] = useState({});
  const dispatch = useDispatch();

  // ----------- FORM AND ERROR CONTROL ---------------
  const { register, getValues } = useForm();

  // -------------------- SUBMIT FORM ------------------------------
  const submitForm = async (data_form) => {
    data_form.lpn = data.lpn;
    // --------- CAMBIAR ESTADO SLOT ----------
    if (data_form.edit_slot) {
      console.log({value: data_form.edit_slot})
      const update_slot = await putSlot(data.id, data_form.edit_slot);
      if (update_slot.status === "Success") {
        dispatch(getPositionsThunk()); // Pido posiciones actualizadas
        dispatch(getPalletsThunk());
        dispatch(closeEditBox());
      } else {
        setErrorProps({
          pass: false,
          msg: `Error: ${update_slot.message || "Undefined"}`, // Error en los datos enviados a la DB
          display: true,
          type: "error",
        });
      }
      return;
    }
    // --------- *********************** ----------
  };

  const submitHandler = (e) => {
    e.preventDefault();
    submitForm(getValues());
  };

  return (
    <div
      width="25vw"
      style={{ padding: "1rem" }}
      className={`${styles.canvasDescarga} ${
        warehouse.dark && styles.canvasDescargaDark
      }`}
    >
      <Form style={{ width: "fit-content" }} onSubmit={submitHandler}>
          <Form.Group className="mb-3">
          <Form.Label>{texts[lang]["EDIT_BOX_CAMBIAR_E"]}</Form.Label>
          <InputGroup>
            <Form.Select {...register("edit_slot")} required>
              <option value="">{texts[lang]["EDIT_BOX_CAMBIAR_SELECT"]}</option>
              <option value="0">{texts[lang]["OFFC_INFO_P_L"]}</option>
              <option value="1">{texts[lang]["OFFC_INFO_P_O"]}</option>
              <option value="2">{texts[lang]["OFFC_INFO_P_B"]}</option>
              <option value="3">{texts[lang]["OFFC_INFO_P_I"]}</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>

        <ButtonSpinner
          variant="contained"
          action={() => submitForm(getValues())}
        >
          {texts[lang]["EDIT_BOX_EDIT_G"]}
        </ButtonSpinner>
      </Form>
      <ErrorOrSuccessMsg {...errorProps}></ErrorOrSuccessMsg>
    </div>
  );
};

export default EditEstado;
