export class PositionsAdmin {
  constructor(position) {
    this.id = position.slotId;
    this.estado = position.status;
    this.tipo_celda = position.comment || "bajo";
    this.cantidad = position["qty"];
    this.lpn = position["lpn"];
    this.sku = position["sku"];
    this.ingreso = position["createdAt"]?.slice(0, 10);
    this.vencimiento = position["expiration"]?.slice(0,10);
    this.producto = position["description"];
    this.family = position["family"]
    this.countryOfDest = position["countryOfDest"]
    this.dateFrom = position["dateFrom"]?.slice(0,10)
    this.dateUntil = position["dateUntil"]?.slice(0,10)
    this.weight = position["weight"]
    this.boxQty = position["boxQty"]
  }
}

export const palletsAdapter = (pallets) => {
  return pallets.map((p) => new PositionsAdmin(p));
};
