import React, { useState } from 'react'
import { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import styles from './OffCanvasPalletIngreso.module.css'
import { Circle, MoveDown } from '@mui/icons-material'
import MaterialReactTable from 'material-react-table'
import { translate_table } from '../Busqueda/Translate'
import { useEffect } from 'react'
import { deleteItemTransfer, getTransferList } from '../../../Configuracion/services/services'
import { Button } from '@mui/material'
import useSocket from '../../../../custom-hooks/useSocket'

const OffCanvasTransferList = ({ visible, setVisible }) => {
  const [data, setData] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const { warehouse } = useContext(ContextoAlmacen)
  const socket = useSocket()

  // Colors Table
  const colors = {
    complete: '#039538',
    transit: '#f87928',
    null: '#607d8b',
    cancel: '#73797e',
    '': '#73797e',
  }

  const translate_status = {
    cancel: 'cancelado',
    null: 'en cola',
    transit: 'transito',
    complete: 'completado',
    waiting: 'iniciado',
    '': 'en cola',
  }

  const columns = [
    {
      accessorKey: 'status',
      header: 'Estado',
      Cell: ({ cell }) => (
        <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
          <Circle sx={{ color: colors[cell.getValue()] }} />
          {translate_status[cell.getValue()]}
        </div>
      ),
    },
    {
      accessorKey: 'slot_id_origin',
      header: 'Origen',
    },
    {
      accessorKey: 'slot_id_destiny',
      header: 'Destino',
    },
  ]

  const getTransferListFromService = async () => {
    try {
      const result = await getTransferList()
      setData(result.result)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTransferListFromService()
  }, [visible])

  useEffect(() => {
    socket.on('state:actualizar-posiciones', async () => {
      try {
        getTransferListFromService()
      } catch (e) {
        console.log(e)
      }
    })
    return () => socket.off('state:actualizar-posiciones')
    // eslint-disable-next-line
  }, [socket])

  const deleteTransferencia = async () => {
    const selected = Object.keys(rowSelection)
      .map((item) => data.filter((d) => d.slot_id_origin === item))
      .flat()
    if (selected.length === 0) return
    await deleteItemTransfer(selected)
    await getTransferListFromService()
  }

  return (
    <Offcanvas
      enforceFocus={false}
      show={visible}
      backdrop={false}
      onHide={() => setVisible(false)}
      placement='end'
      className={`${styles.canvasDescarga} ${warehouse.dark && styles.canvasDescargaDark}`}
      sx={{width: ""}}
    >
      <Offcanvas.Header closeButton closeVariant={warehouse.dark ? 'white' : 'black'}>
        <Offcanvas.Title>
          <div className={styles.wrapperTitle}>
            <MoveDown /> Transferencia Generadas
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.canvaBody}>
        <MaterialReactTable
          columns={columns}
          data={data || []}
          localization={translate_table}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
          muiTableContainerProps={{
            sx: { height: '67vh', backgroundColor: warehouse.dark ? '#292929' : '#fff' },
          }}
          muiToolbarAlertBannerProps={{
            sx: {
              color: warehouse?.dark === true && '#ffffff',
              backgroundColor: warehouse?.dark === true && '#356161',
            },
          }}
          initialState={{
            density: 'compact',
            pagination: {
              pageIndex: 0,
              pageSize: 50,
            },
          }}
         
          defaultColumn={{
            maxSize: 100,
            minSize: 50,
            size: 100, //default size is usually 180
          }}
          enableColumnActions={false}
          enableTopToolbar={false} //hide top toolbar.
          enableBottomToolbar={true} //hide bottom toolbar.
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          getRowId={(row) => row.slot_id_origin}
          state={{ rowSelection }}
          renderBottomToolbarCustomActions={() => (
            <div className={styles.wrapper}>
              <div className={styles.colorPickerWrapper}>
                <Button
                  style={{ padding: '6px' }}
                  size='small'
                  onClick={deleteTransferencia}
                  variant='contained'
                  color='error'
                >
                  Borrar
                </Button>
              </div>
            </div>
          )}
          // --------- ************* ----------------
        />
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default OffCanvasTransferList
