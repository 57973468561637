import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import botonAutonomo from "../../../../assets/imgs/botonAutonomo.png";
import botonAutonomoCarga from "../../../../assets/imgs/botonAutonomoCarga.png";
import botonAutonomoDescarga from "../../../../assets/imgs/botonAutonomoDescarga.png";
import botonBusqueda from "../../../../assets/imgs/botonBusqueda.png";
import listaBusqueda from "../../../../assets/imgs/listaBusqueda.png";
import listaOrdenDeDescarga from "../../../../assets/imgs/listaOrdenDeDescarga.png";
import listaConfirmarOrdenDeDescarga from "../../../../assets/imgs/listaConfirmarOrdenDescarga.png";
import menuOrdenDeDescarga from "../../../../assets/imgs/menuOrdenDeDescarga.png";
import { ContextoAlmacen } from "../../../../contexts/Almacen/AlmacenContext";
import styles from "./InfoModal.module.css";
import { FcCursor } from "react-icons/fc";
import { texts } from "../../../../i18n";

function InfoModal({ visible, setVisible }) {
  const { warehouse } = useContext(ContextoAlmacen);
  const { lang } = warehouse;

  return (
    <>
      <Modal
        size="xl"
        show={visible}
        onHide={() => setVisible(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        contentClassName={
          warehouse.dark ? styles.modalInfoDark : styles.modalInfo
        }
        centered
      >
        <Modal.Header
          closeButton
          className={styles.titulo}
          closeVariant={warehouse.dark && "white"}
        >
          <Modal.Title id="example-modal-sizes-title-lg">
            {texts[lang]["MOD_INFO_TITLE"]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto" }} className={styles.bodyModal}>
          <div className={styles.seccion}>
            <h5 className={styles.tituloSeccion}>
              {texts[lang][" MOD_INFO_SUB_C"]}
            </h5>
            <p>{texts[lang]["MOD_INFO_TEXT_DES"]}</p>
            <div className={styles.singleLine}>
              <div className={styles.divIcon} style={{ marginLeft: "1rem" }}>
                <FcCursor className={styles.icons} />
                {texts[lang]["MOD_INFO_CLICK"]}
              </div>
              <p>{texts[lang]["MOD_INFO_POS_TEXT"]}</p>
            </div>
            <div className={styles.singleLine}>
              <div
                className={`${styles.customIcon} ${
                  warehouse.dark && styles.customIconDark
                }`}
              >
                Ctrl
              </div>
              <p style={{ fontSize: "2rem" }}>+</p>
              <div className={styles.divIcon}>
                <FcCursor className={styles.icons} />
                {texts[lang]["MOD_INFO_CLICK"]}
              </div>
              <p>{texts[lang]["MOD_INFO_POS_EDIT"]}</p>
            </div>

            <div className={styles.singleLine}>
              <div
                className={`${styles.customIcon} ${
                  warehouse.dark && styles.customIconDark
                }`}
              >
                Shift
              </div>
              <p style={{ fontSize: "2rem" }}>+</p>
              <div className={styles.divIcon}>
                <FcCursor className={styles.icons} />
                {texts[lang]["MOD_INFO_CLICK"]}
              </div>
              <p>{texts[lang]["MOD_INFO_POS_INTER"]}</p>
            </div>

            <div className={styles.singleLine}>
              <div
                className={`${styles.customIcon} ${
                  warehouse.dark && styles.customIconDark
                }`}
              >
                Shift
              </div>
              <p style={{ fontSize: "2rem" }}>+</p>
              <div
                className={`${styles.customIcon} ${
                  warehouse.dark && styles.customIconDark
                }`}
              >
                Num
              </div>
              <p>{texts[lang]["MOD_INFO_POS_CHANGE_L"]}</p>
            </div>
          </div>

          <div className={styles.seccion}>
            <h5 className={styles.tituloSeccion}>
              {texts[lang]["MOD_INFO_POS_STATUS_TITLE"]}
            </h5>
            <p>{texts[lang]["MOD_INFO_POS_STATUS_TEXT"]}</p>
            <p className={styles.enumerados}>
              <strong>
                {texts[lang]["MOD_INFO_POS_STATUS_O"]}{" "}
                <div className={styles.luzActivo}></div>:
              </strong>
              {texts[lang]["MOD_INFO_POS_STATUS_O_TEXT"]}
            </p>
            <p className={styles.enumerados}>
              <strong style={{ paddingRight: "10px", fontWeight: "300" }}>
                {texts[lang]["MOD_INFO_POS_STATUS_L"]}{" "}
                <div className={styles.luzActivo}></div> :
              </strong>
              {texts[lang]["MOD_INFO_POS_STATUS_L_TEXT"]}
            </p>
            <p className={styles.enumerados}>
              <strong style={{ paddingRight: "10px", fontWeight: "300" }}>
                {texts[lang]["MOD_INFO_POS_STATUS_E"]}{" "}
                <div className={styles.luzActivoError}></div> :
              </strong>
              {texts[lang]["MOD_INFO_POS_STATUS_E_TEXT"]}
            </p>
          </div>
          <div className={styles.seccion}>
            <h5 className={styles.tituloSeccion}>
              {texts[lang]["MOD_INFO_POS_FILTROS_TITLE"]}
            </h5>
            <p>{texts[lang]["MOD_INFO_POS_FILTROS_TEXT"]}</p>
          </div>
          <div className={styles.seccion}>
            <h3 className={styles.tituloSeccion}>
              {texts[lang]["MODAL_CHARGUE_MODE_TITLE"]}
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "2rem 0px",
              }}
            >
              <div>
                <img
                  style={{ width: "20vw", borderRadius: "8px" }}
                  src={botonAutonomo}
                  alt="Boton del autonomo"
                />
              </div>
              <p>{texts[lang]["MODAL_CHARGUE_MODE_INSTRUCTION_ONE"]}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "2rem 0px",
              }}
            >
              <img
                style={{ width: "20vw", borderRadius: "8px" }}
                src={botonAutonomoCarga}
                alt="Boton de carga del autonomo"
              />
              <p>{texts[lang]["MODAL_CHARGUE_MODE_INSTRUCTION_TWO"]}</p>
            </div>
          </div>

          <div className={styles.seccion}>
            <h3 className={styles.tituloSeccionDescarga}>
              {texts[lang]["MODAL_DOWNLOAD_MODE_TITLE"]}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "2rem 0px",
            }}
          >
            <img
              style={{ width: "20vw", borderRadius: "8px" }}
              src={botonBusqueda}
              alt="Boton de busqueda"
            />
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE"]}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2rem 0px",
              gap: "2rem",
            }}
          >
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO"]}</p>
            <img
              style={{ width: "50vw" }}
              src={listaBusqueda}
              alt="Lista de busqueda"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "2rem 0px",
            }}
          >
            <img
              style={{ width: "12vw", borderRadius: "8px" }}
              src={menuOrdenDeDescarga}
              alt="Menu de ordenes de descarga"
            />
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE"]}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2rem 0px",
              gap: "2rem",
            }}
          >
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR"]}</p>
            <img
              style={{ width: "50vw" }}
              src={listaConfirmarOrdenDeDescarga}
              alt="Lista de busqueda"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "2rem 0px",
            }}
          >
            <img
              style={{ width: "25vw" }}
              src={listaOrdenDeDescarga}
              alt="Botones de la lista Ordenes de descarga"
            />
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE"]}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "2rem 0px",
            }}
          >
            <img
              style={{ width: "20vw" }}
              src={botonAutonomoDescarga}
              alt="Boton autonomo de descarga"
            />
            <p>{texts[lang]["MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX"]}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InfoModal;
