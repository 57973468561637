import styled, { css, keyframes } from "styled-components";

const glow = keyframes`
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.5);
  }
  100% {
    filter: brightness(1);
  }
`;


export const BoxDraw = styled.div.attrs((props) => ({
  style: {
    background: props.color,
    cursor: !props.$cursor ? 'pointer' : '',
    boxShadow: props.selected ? 'rgb(167 203 224) 0px 0px 0px 8px' : ''
  },
}))`
  z-index: 20;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  color: #fff;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  animation: ${(props) => (props.inList ? css`${glow} 2s infinite` : 'none')};
  background-color: ${(props) => (props.waiting && "#bb5098 !important")};

  &:hover {
    background-color: lightblue !important;
  }
`