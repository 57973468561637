// --------------------------- SERVICIOS GENERICOS --------------------------------
const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const saveToXLS = async (data) => {
    try {
        const response = await fetch(SERVER_URL + '/docs/xls',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}

export const saveToPDF = async (data) => {
    try {
        const response = await fetch(SERVER_URL + '/docs/pdf',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}