import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirm from "../../../../components/ModalConfirm/ModalConfirm";
import {
  getConfigCargaThunk,
  getConfigDescargaThunk,
} from "../../redux/slices/configuracion/thunk/getConfigCargaThunk";
import {
  updateAutonomoCargaConfig,
  updateAutonomoDescargaConfig,
} from "../../services/services";
import DaysSwitch from "./DaysSwitch";
import TransferList from "./TransferList";

const ReglaGeneral = () => {
  const [openModal, setOpenModal] = useState(false);
  const config = useSelector((state) => state.configuracion_autonomo);
  const { active, date_props, download_config, download_exit, ingreso } =
    config;
  const dispatch = useDispatch();
  const [value, setValue] = useState(download_config);
  const [exit, setExit] = useState(download_exit);
  const [valuePalletIngreso, setValuePalletIngreso] = useState(ingreso);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  /* const handleChangePalletIngreso = (e) => {
    setValuePalletIngreso(e.target.value)
  } */

  useEffect(() => {
    dispatch(getConfigCargaThunk());
    dispatch(getConfigDescargaThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue(download_config);
    setExit(download_exit);
    setValuePalletIngreso(ingreso);
    // eslint-disable-next-line
  }, [download_config, config]);

  const guardarAutonomo = async () => {
    try {
      const regla = active.map((r) => {
        return {
          property: r.accessorKey,
          days: date_props.value,
          ingreso: valuePalletIngreso,
        };
      });
      const saveAutonomo = await updateAutonomoCargaConfig(regla);
      const saveAutonomoDescarga = await updateAutonomoDescargaConfig({
        config: value,
        exit: exit,
      });
      if (
        saveAutonomo.status === "Success" &&
        saveAutonomoDescarga.status === "Success"
      ) {
        setOpenModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack direction={"column"} justifyContent="space-between" gap="2rem">
      <Stack direction={"row"} gap="2rem">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            marginTop: "1rem",
          }}
        >
          <div>
            <h4>Configuración Carga</h4>
            <p>
              Reglas generales de almacenamiento, agrupación en calles según
              propiedades agrupadas y toleracia.
            </p>
          </div>
          <TransferList />
          <ModalConfirm
            open={openModal}
            setOpen={setOpenModal}
            title="Confirmar configuración"
            confirm={guardarAutonomo}
          >
            Se aplicara la siguiente configuración para las proximas operaciones
            del Autónomo de carga y descarga.
          </ModalConfirm>
        </div>
        <div>
          <h4>Configuración Descarga</h4>
          <p>
            Optimizar la descargas por cantidad de movimientos o FEFO estricto.
          </p>
          <Box sx={{ marginTop: "3rem" }}>
            <Stack direction="column" gap={"1rem"}>
              {/* <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  sx={{ fontWeight: "bold" }}
                >
                  Prioridad de Descarga
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="fefo"
                    control={<Radio />}
                    label="FEFO"
                  />
                  <FormControlLabel
                    value="movimientos"
                    control={<Radio />}
                    label="Movimientos"
                  />
                </RadioGroup>
              </FormControl> */}
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  sx={{ fontWeight: "bold" }}
                >
                  Salida
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={exit}
                  onChange={(e) => setExit(e.target.value)}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Linea 1"
                  />
                </RadioGroup>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={exit}
                  onChange={(e) => setExit(e.target.value)}
                >
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Linea 2"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>
        </div>
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DaysSwitch />
        <Button
          variant="contained"
          startIcon={<Save />}
          onClick={() => setOpenModal(true)}
        >
          Guardar
        </Button>
      </div>
    </Stack>
  );
};

export default ReglaGeneral;
