// ------------------- Constructor de Posiciones. ------------

const statusText = {
  0: 'Free',
  1: 'Ocuppied',
  2: 'Blocked',
  3: 'Disabled',
};

export class Positions {
  constructor(position) {
    this.id = position.slotId;
    this.estado = position.status;
    this.status = statusText[position.status];
    this.tipo_celda = position.comment || 'bajo';
    this.lpn = position['Pallet.lpn'];
    this.sku = position['Pallet.sku'];
    this.ingreso = position['Pallet.createdAt']?.slice(0, 10);
    this.vencimiento = position['Pallet.expiration']?.slice(0, 10);
    this.producto = position['Pallet.description'];
    this.family = position['Pallet.family'];
    this.countryOfDest = position['Pallet.countryOfDest'];
    this.dateFrom = position['Pallet.dateFrom']?.slice(0, 10);
    this.dateUntil = position['Pallet.dateUntil']?.slice(0, 10);
    this.weight = position['Pallet.weight'];
    this.boxQty = position['Pallet.boxQty'];
    this.ingreso = position['Pallet.createdAt'];
  }
}

export const positionsAdapter = (positions) => {
  const positions_object = {};
  for (const pos of positions) {
    positions_object[pos.slotId] = new Positions(pos);
  }
  return positions_object;
};
// ----------------- ************************ ---------------

// --------------- Adapter Filtros -----------------------------
export const filterAdapter = (response, prop) => {
  if (prop === 'vencimiento') {
    return response.result.slice(-1)[0];
  }
  if (prop === 'ingreso') {
    return response.result[0];
  }

  return response.result;
};
// Adapter de parametros a enviar por query
export const getFilterAdapter = (prop) => {
  const adapt = {
    sku: 'sku',
    cliente: 'area',
    vencimiento: 'expiration',
    ingreso: 'createdAt',
    family: 'family',
    countryOfDest: 'countryOfDest',
  };
  return adapt[prop];
};
// ----------------- ************************ ---------------

// ---------- Adapter POST Unidad de Carga Loadunit ----------------

export const loadUnitAdapter = (data) => {
  return {
    ...data,
    slotId: data.slotId,
    lpn: data.lpn,
    status: 'saved',
  };
};

export const putLoadUnitAdapter = (data, id) => {
  if (id) {
    return {
      lpn: data.lpn,
      slotId: id,
    };
  }
};

// ----------------- ************************ ---------------
