import React, { useState } from 'react'
import { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import styles from './InfoBox.module.css'
import { BiPackage } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { closeInfoBox } from '../../redux/slices/positionClickSlice/positionClickSlice'
import { /* Button, */ IconButton, Stack, Tooltip, Typography } from '@mui/material'
import useSocket from '../../../../custom-hooks/useSocket'
import moment from 'moment'
import { texts } from '../../../../i18n'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import decodeID from '../../../../utils/decodeId'
import {  postTransferList } from '../../../Configuracion/services/services'
import { setListaDescarga } from '../../../Descarga/redux/slices/lista/listaDescargaSlice'
import { AddRoad, DoneAll, Download , Error , MoveDown } from '@mui/icons-material'
import { filterStreet, listToTransfer } from '../../../../utils/transfer'

const InfoBox = () => {
  const info = useSelector((state) => state.positionClick.info)
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const posiciones = useSelector((state) => state.positions.positions)
  const { destino } = useSelector((state) => state.operation)

  const { data_format } = useContext(ContextoData)
  const dispatch = useDispatch()
  const [savedInList, setSavedInList] = useState(false)
  const [savedInListStreet, setSavedInListStreet] = useState(false)
  //const [disableButton, setDisableButton] = useState(false)
  const [transferStatus, setTransferStatus] = useState(null)
  const profundidades = warehouse.profundidades

  const socket = useSocket()

  const sendToDownload = () => {
    dispatch(setListaDescarga({ lista: [info.content] }))
    setSavedInList(true)
    setTimeout(() => {
      setSavedInList(false)
    }, 3000)
  }


  const sendToTransferList = async () => {
    if (!destino) {
      setTransferStatus({ status: 'error', msg: 'Debe seleccionar una calle vacía como destino' })
      setTimeout(() => setTransferStatus(null), 2000)

      return
    }
    const list = await listToTransfer(info.content.id, profundidades, destino, posiciones);
    
    try {
      await postTransferList(list)
      setTransferStatus({ status: 'ok', msg: 'Transferencia generada exitosamente' })
      setTimeout(() => setTransferStatus(null), 2000)
      socket.emit('action:actualizar-socket')
    } catch (e) {
      console.log(e)
    }
  }

  const sendToDownloadStreet = () => {
    const decode = decodeID(info.content.id)
    const lista = filterStreet(`L${decode.lado}C${decode.calle}N${decode.nivel}`, posiciones)
    dispatch(setListaDescarga({ lista }))
    setSavedInListStreet(true)
    setTimeout(() => {
      setSavedInListStreet(false)
    }, 3000)
  }

  // INGRESO PALLETS LINEA 1
  /* const ingresarPallets = () => {
    setDisableButton(true)
    socket.emit('action:ingresar-pallets')
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  }

  const reingresarPallets = () => {
    setDisableButton(true)
    socket.emit('action:reingresar-pallets')
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  } */

  const position_status = {
    0: texts[lang]['OFFC_INFO_P_L'],
    1: texts[lang]['OFFC_INFO_P_O'],
    2: texts[lang]['OFFC_INFO_P_B'],
    3: texts[lang]['OFFC_INFO_P_I'],
  }

  function statusBox(dataPosicion) {
    if (!dataPosicion) return

    /* if (dataPosicion.id === 'L1C7N1P1') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {dataPosicion.estado === 1 && (
            <ul>
              <li>
                {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
              </li>
              <li>
                {texts[lang]['OFFC_INFO_E']}: {texts[lang]['OFFC_INFO_P_O']}
              </li>
              <li>
                {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
              </li>
              {data_format.map((column, key) => (
                <li key={key}>
                  {column.header}: {dataPosicion[column.accessorKey]}
                </li>
              ))}
            </ul>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '50px',
              left: '50px',
            }}
          >
            <Stack
              direction={'column'}
              justifyContent='space-between'
              style={{ height: '-webkit-fill-available' }}
              gap={'1rem'}
            >
              <Button onClick={ingresarPallets} color='custom' variant='contained' disabled={disableButton}>
                {texts[lang]['OFFC_INFO_BUTTON_ING']}
              </Button>
              <Button onClick={reingresarPallets} color='custom' variant='contained' disabled={disableButton}>
                {texts[lang]['OFFC_INFO_BUTTON_REING']}
              </Button>
            </Stack>
          </div>
        </div>
      )
    } */

    if (dataPosicion.estado === 1 || dataPosicion.estado === 2) {
      return (
        <Stack justifyContent='space-between' style={{ height: '-webkit-fill-available' }}>
          <ul>
            <li>
              {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
            </li>
            <li>
              {texts[lang]['OFFC_INFO_E']}: {position_status[dataPosicion.estado]}
            </li>
            <li>
              {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
            </li>
            {data_format.map((column, key) => {
              return (
                <li key={key}>
                  {column.header}:{' '}
                  {column.accessorKey === 'vencimiento'
                    ? moment(dataPosicion[column.accessorKey]).format('DD-MM-YYYY')
                    : dataPosicion[column.accessorKey]}
                </li>
              )
            })}
          </ul>
          <Stack direction='column'>
            <Stack gap={'1rem'} direction='row' justifyContent={'start'} alignItems='center' width={'100%'}>
              <Typography variant='h5' fontWeight='bold'>
              Acciones:
              </Typography>
              <Tooltip title='Enviar a Descarga' placement='top-start' arrow>
                <IconButton onClick={sendToDownload} variant='contained' color={'custom'}>
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title='Descarga Calle' placement='top-start' arrow>
                <IconButton onClick={sendToDownloadStreet} variant='contained' color={'custom'}>
                  <AddRoad />
                </IconButton>
              </Tooltip>
              <Tooltip title='Transferir Calle' placement='top-start' arrow>
                <IconButton onClick={sendToTransferList} variant='contained' color={'custom'}>
                  <MoveDown />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack alignContent={'center'} padding='5px' height={'40px'}>
              {transferStatus && (
                <p style={{ padding: '5px' }}>
                  {transferStatus.status === 'error' ? <Error /> : <DoneAll />} {transferStatus.msg}
                </p>
              )}
              {savedInList && (
                <p style={{ padding: '5px' }}>
                  <DoneAll /> Enviado a lista de descarga
                </p>
              )}
              {savedInListStreet && (
                <p style={{ padding: '5px' }}>
                  <DoneAll /> Enviado calle completa
                </p>
              )}
            </Stack>
          </Stack>
        </Stack>
      )
    }

    return (
      <div>
        <p>
          {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
        </p>
        <p>
          {texts[lang]['OFFC_INFO_E']}: {position_status[dataPosicion.estado]}
        </p>
        <p>
          {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
        </p>
      </div>
    )
  }

  const handleClose = () => {
    dispatch(closeInfoBox())
  }

  return (
    <>
      <Offcanvas
        show={info.display}
        backdrop={false}
        onHide={handleClose}
        placement='start'
        className={styles.canvas}
        enforceFocus={false}
      >
        <Offcanvas.Header closeVariant={'white'} closeButton>
          <Offcanvas.Title>
            <BiPackage /> {texts[lang]['TITLE_INFO']}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.canvaBody}>{statusBox(info.content)}</Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default InfoBox
