import moment from "moment";

export const historyAdapter = (response) => {
  const translate = {
    pallet_created: 'Ingreso',
    save_in: 'Guardado',
    manual_in: 'Guardado Manual',
    exit: 'Salida',
    manual_exit: 'Salida Manual',
    relocation: 'Reubicacion',
    manual_relocation: 'Reubicacion Manual',
    rejection: 'Rechazo de pallet',
    line_end: 'Pick Up',
    trans: 'Transelevador',
    manual_delete: 'Eliminacion Manual',
  };

  // 'pallet_created',
  // 'line_end',
  // 'save_in',
  // 'trans',
  // 'relocation',
  // 'exit',
  // 'manual_relocation',
  // 'manual_in',
  // 'rejected',
  // 'manual_exit',

  const data = response.map((d) => {
    return {
      ...d,
      updatedAt: d.updatedAt.slice(0, 19),
      createdAt: d.createdAt.slice(0, 19),
      dateFrom: d?.dateFrom?.slice(0, 10),
      dateUntil: d?.dateFrom?.slice(0, 10),
      operation: translate[d.operation],
    };
  });

  return data;
};
