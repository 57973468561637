import React from 'react'
import io from 'socket.io-client'

const socket_server = 'https://localhost:4200'
const socket = io(socket_server, { transports: ['websocket'] })
// socket.onAny((event, ...args) => {
//   console.log(`Evento recibido: ${event}, Datos:`, args);
// })
export const SocketContext = React.createContext()
export const SocketProvider = (props) => {
  return <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>
}
