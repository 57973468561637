import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AlmacenProvider from './contexts/Almacen/AlmacenContext';
import { AppThemeMui } from './contexts/ThemeMui/AppThemeMui';
import { SocketProvider } from './contexts/Socket/SocketContext';
import DataContextProvider from './contexts/Data/DataContext';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import Almacen from './pages/Almacen/Almacen';
import Navbar from './components/Navbar/Navbar';
import { useRef } from 'react';
import AlertCtto2 from './components/AlertCtto/AlertCtto2';
import Descarga from './pages/Descarga/Descarga';
import Configuracion from './pages/Configuracion/Configuracion';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminPosiciones from './pages/AdminPosiciones/AdminPosiciones';
import MachineLogs from './pages/MachineLogs/MachineLogs';


function App() {
  const firstRender = useRef(true)

  return (
    <Provider store={store}>
      <SocketProvider>
        <BrowserRouter>
          <AlmacenProvider>
            <DataContextProvider>
              <AppThemeMui>
                <Navbar />
                <Routes>
                  <Route path='/' element={<Almacen firstRender={firstRender} />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/descarga' element={<Descarga />} />
                  <Route path='/config' element={<Configuracion />} />
                  <Route path='/admin-pos' element={<AdminPosiciones />} />
                  <Route path='/machine' element={<MachineLogs />} />
                </Routes>
                <AlertCtto2 />
              </AppThemeMui>
            </DataContextProvider>
          </ AlmacenProvider>
        </BrowserRouter>
      </SocketProvider>
    </Provider >
  );
}

export default App;