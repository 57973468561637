import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { translate_table } from '../../../Almacen/components/Busqueda/Translate'
import MaterialReactTable from 'material-react-table'
import { getPalletsThunk } from '../../redux/slices/configuracion/thunk/getPalletsThunk'
import ExportButtons from '../../../../components/ExportButtons/ExportButtons'
import { MenuItem } from '@mui/material'
import EditBox from '../../../Almacen/components/EditBox/EditBox'
import { setEdit } from '../../../Almacen/redux/slices/positionClickSlice/positionClickSlice'

const AdminPosicionesTable = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { data_format_pallets } = useContext(ContextoData)
  const dispatch = useDispatch()
  const pallets = useSelector((state) => state.pallets.pallets)
  const columns = useMemo(() => [...data_format_pallets], [data_format_pallets])
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('admin_pos_visibility') ? JSON.parse(localStorage.getItem('admin_pos_visibility')) : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  let data = pallets || []
  useEffect(() => {
    localStorage.setItem('admin_pos_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])

  useEffect(() => {
    dispatch(getPalletsThunk()) //eslint-disable-next-line
  }, [])

  const handleClick = (row) => {
    dispatch(setEdit({ ...row.original, id: row.original.id }))
    return
  }

  return (
    <>
      <MaterialReactTable
        enableFilterMatchHighlighting
        columns={columns}
        data={data || []}
        localization={translate_table}
        getRowId={(row) => row.id}
        state={{ columnVisibility }}
        onColumnVisibilityChange={setColumnVisibility}
        muiTableContainerProps={{
          sx: { height: '60vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', width: '95vw' },
        }}
        initialState={{
          density: 'compact',
          pagination: {
            pageIndex: 0,
            pageSize: 50,
          },
        }}
        muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
        muiToolbarAlertBannerProps={{
          sx: {
            color: warehouse?.dark === true && '#ffffff',
            backgroundColor: warehouse?.dark === true && '#356161',
          },
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const filters = table?.getAllColumns()?.reduce((acc,elm) => elm.getFilterValue() ? ({...acc, [elm.id]: elm.getFilterValue()}) : acc,{});
          return <ExportButtons filter={filters} data={table.getFilteredRowModel().rows} title='Pallets' />
        }}
        enableRowActions
        renderRowActionMenuItems={({ row }) => [
          <MenuItem key='edit' onClick={() => handleClick(row)}>
            Editar
          </MenuItem>,
        ]}
      />
      <EditBox />
    </>
  )
}

export default AdminPosicionesTable
