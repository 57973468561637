import React, { useContext, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FaPallet } from 'react-icons/fa'
import ButtonSpinner from '../../../../../components/ButtonSpinner/ButtonSpinner'
import styles from './EditBoxForm.module.css'
import ErrorOrSuccessMsg from '../../../../../components/ErrorOrSuccessMsg/ErrorOrSuccesMsg'
import { formErrors } from '../../../../../custom-hooks/useFormErrors'
import { deleteLoadUnit, getDataFromLPN, putDataByLPN, putSlot } from '../../../services/services'
import { ContextoAlmacen } from '../../../../../contexts/Almacen/AlmacenContext'
import { useDispatch } from 'react-redux'
import { getPositionsThunk } from '../../../redux/slices/positions/thunk/positionsThunk'
import { closeEditBox } from '../../../redux/slices/positionClickSlice/positionClickSlice'
import { getPalletsThunk } from '../../../../AdminPosiciones/redux/slices/configuracion/thunk/getPalletsThunk'
import { texts } from '../../../../../i18n'

const EditBoxForm = ({ ...data }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  // ----------- FORM AND ERROR CONTROL ---------------
  const { register, getValues, reset } = useForm()
  const [errorProps, setErrorProps] = useState({})
  const dispatch = useDispatch()
  // ------------- ********************** ---------------

  // -------------------- SUBMIT FORM ------------------------------
  const submitForm = async (data_form) => {
    // let data_api
    let data_db

    // ----------------- ERROR EN EL FORMULARIO ---------------
    if (!data_form.lpn) {
      return
    }
    const control = formErrors(data_form)
    if (!control.pass) {
      setErrorProps(control)
      return
    }
    // ----------------------- END CONTROL FORM---------------------------------

    try {
      // ------------------ SI EL PALLET EXISTE EN LA DB ------------------------

      try {
        data_db = await getDataFromLPN(data_form.lpn)
        const {slotId} = data_db[0]

        if (data_db.length > 0) {

          if (!slotId !== null || !slotId !== undefined) {
            setErrorProps({
              pass: false,
              msg: `${texts[lang]['EDIT_BOX_ERROR_1']} ${slotId}`,
              display: true,
              type: 'error',
            })
            return
          }

          const newLoadUnit = await putDataByLPN(data_form.lpn, {
            slotId: data.id,
          })
          if (newLoadUnit.status === 'Success') {
            dispatch(getPositionsThunk())
            dispatch(getPalletsThunk())
            dispatch(closeEditBox())
            setErrorProps(control)
            reset()
          } else {
            setErrorProps({
              pass: false,
              msg: `Error: ${newLoadUnit.message}`,
              display: true,
              type: 'error',
            })
          }
          return
        }else{

          throw Error("No existe pallet")
        }


      } catch {
        setErrorProps({
          pass: false,
          msg: texts[lang]['EDIT_BOX_ERROR_2'],
          display: true,
          type: 'error',
        })
        return
      }

      // --------------------------------------------------------
      // ------------------ SI EL PALLET NO EXISTE EN LA DB ------------------------

      // try {
      //   //console.log('CONSULTANDO API')
      //   data_api = await getDataAPI(data_form.lpn)
      // } catch (error) {
      //   setErrorProps({
      //     pass: false,
      //     msg: `Error: ${'No se pudo obtener datos del WMS'}`,
      //     display: true,
      //     type: 'error',
      //   })
      //   return
      // }

      // const newLoadUnit = await postLoadUnit({
      //   ...data_api,
      //   slotId: data.id,
      //   status: 'saved',
      //   pallet_type: data_form.pallet_type,
      // })
      // await putDataByLPN(data_form.lpn, { slotId: data.id })
      // if (newLoadUnit.status === 'Success') {
      //   dispatch(getPositionsThunk())
      //   dispatch(getPalletsThunk())
      //   dispatch(closeEditBox())
      //   setErrorProps(control)
      //   reset()
      // } else {
      //   setErrorProps({
      //     pass: false,
      //     msg: `Error: ${newLoadUnit.message}`,
      //     display: true,
      //     type: 'error',
      //   })
      // }
    } catch (error) {
      setErrorProps({
        pass: false,
        msg: `Error: ${error}`,
        display: true,
        type: 'error',
      })
    }
  }
  // -------------- *********************** --------------------

  // --------------- DELETE LOAD UNIT ----------------
  const deleteHandler = async () => {
    const lpn = data?.lpn
    if (!lpn) {
      const deleted = await putSlot(data.id, 0)

      if (deleted.status === 'Success') {
        dispatch(getPositionsThunk())
        dispatch(getPalletsThunk())
        dispatch(closeEditBox())
      }
      return
    }
    try {
      const deleteLoadUnitResult = await deleteLoadUnit(lpn)
      await putSlot(data.id, 0)
      if (deleteLoadUnitResult.status === 'Success') {
        dispatch(getPositionsThunk())
        dispatch(getPalletsThunk())
        dispatch(closeEditBox())
      } else {
        setErrorProps({
          pass: false,
          msg: texts[lang]['EDIT_BOX_ERROR_3'],
          display: true,
          type: 'error',
        })
      }
    } catch (e) {
      setErrorProps({
        pass: false,
        msg: `Error: ${e}`,
        display: true,
        type: 'error',
      })
    }
  }

  // -------------- *********************** --------------------
  const submitHandler = (e) => {
    e.preventDefault()
    submitForm(getValues())
  }

  return (
    <>
      <div
        style={{ padding: '1rem' }}
        className={`${styles.canvasDescarga} ${warehouse.dark && styles.canvasDescargaDark}`}
      >
        <div className={styles.wrapperTitle}>
          <FaPallet /> {texts[lang]['EDIT_BOX_EDIT_T']}
        </div>

        <Form style={{ width: '-webkit-fill-available' }} onSubmit={submitHandler}>
          <Form.Group className='mb-3'>
            <Form.Label>LPN </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder='lpn'
                aria-label='lpn'
                aria-describedby='basic-addon2'
                {...register('lpn')}
                required
                defaultValue={data.lpn || ''}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>{texts[lang]['EDIT_BOX_EDIT_TIPE']}</Form.Label>
            <InputGroup>
              <Form.Select {...register('pallet_type')} required>
                <option value='bajo'>{texts[lang]['EDIT_BOX_EDIT_TIPE_B']}</option>
                <option value='alto'>{texts[lang]['EDIT_BOX_EDIT_TIPE_A']}</option>
              </Form.Select>
            </InputGroup>
          </Form.Group>

          <div className={styles.botonera}>
            <ButtonSpinner variant='contained' action={() => submitForm(getValues())}>
              {texts[lang]['EDIT_BOX_EDIT_G']}
            </ButtonSpinner>
            <ButtonSpinner style={{ height: 'min-content' }} type='peligro' variant='contained' action={deleteHandler}>
              {texts[lang]['EDIT_BOX_EDIT_E']}
            </ButtonSpinner>
          </div>
          <ErrorOrSuccessMsg {...errorProps}></ErrorOrSuccessMsg>
        </Form>
      </div>
    </>
  )
}

export default EditBoxForm
