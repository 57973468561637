import { palletsAdapter } from '../adapter/adapters';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// +++++++++++++++++++++++++  PALLETS PARA ADMINISTRADOR DE POSICIONS  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getPallets = async () => {
  try {
    const response = await fetch(SERVER_URL + '/pallet?format=ctto', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const result = await response.json();
    return palletsAdapter(result);
  } catch (error) {
    throw Error('No se puede obtener los pallets');
  }
};

// +++++++++++++++++++++++++  PALLETS PARA ADMINISTRADOR DE POSICIONS  ++++++++++++++++++++++++++++++++++++++++++++++++++

export const updateRowValues = async (original, newValues) => {
  try {
    const response = await fetch(SERVER_URL + '/pallet/SKU', {
      method: 'PUT',
      body: JSON.stringify({ original, newValues }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const { result } = await response.json();
    return result;
  } catch (error) {
    throw Error('No se puede obtener los pallets');
  }
};
