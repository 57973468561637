import React, { useContext, useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { translate_table } from '../../../Almacen/components/Busqueda/Translate'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { useDispatch, useSelector } from 'react-redux'
import { getConfirmOrdersThunk } from '../../redux/slices/lista/thunk/getConfirmOrdersThunk'
import { Cancel, InfoOutlined } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import styles from './ListaDescargaTable.module.css'
import { updateDownloadOrdersConfirm } from '../../services/services'
import ModalConfirm from '../../../../components/ModalConfirm/ModalConfirm'
import ExportButtons from '../../../../components/ExportButtons/ExportButtons'

const ConfirmadasTable = () => {
  const { data_format_descarga_confirm } = useContext(ContextoData)
  const columns = useMemo(() => [...data_format_descarga_confirm], [data_format_descarga_confirm])
  const { warehouse } = useContext(ContextoAlmacen)
  const dispatch = useDispatch()
  const data = useSelector((state) => state.lista_descarga.confirm_orders.lista).filter(
    (l) => l?.estado !== 'pendiente'
  )
  // -------------------- SELECT ROWS -----------------
  const [rowSelection, setRowSelection] = useState({})
  const [open, setOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  // --------- GET POSITIONS -----------------
  useEffect(() => {
    dispatch(getConfirmOrdersThunk()) // eslint-disable-next-line
  }, [])

  // ------------ VISIBILITY COLUMN SAVE ---------------------------
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('download_confirm_column_visibility')
      ? JSON.parse(localStorage.getItem('download_confirm_column_visibility'))
      : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  useEffect(() => {
    localStorage.setItem('download_confirm_column_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])
  // ------------------------- ******************** ---------------------

  // --------------- CHANGE STATUS TO CANCEL -----------------
  const hadleChangeState = async () => {
    const items = Object.keys(rowSelection)
      .map((item) => data[item])
      .filter((i) => i?.estado === 'en cola')
    if (items?.length > 0) {
      const update_lps = items.map((i) => i.lpn)
      try {
        await updateDownloadOrdersConfirm({ lpn: update_lps, dl_status: 'cancel' })
        dispatch(getConfirmOrdersThunk())
        setRowSelection({})
        setOpen(false)
      } catch (error) {
        setErrorMsg(error.message)
      }
      return
    }

    setErrorMsg('Los pallets seleccionados no se pueden cancelar.')
    return
  }
  // ------------------------- ******************** ---------------------

  return (
    <MaterialReactTable
      columns={columns}
      data={data || []}
      localization={translate_table}
      enableRowSelection
      positionExpandColumn='first'
      state={{ rowSelection, columnVisibility }}
      onRowSelectionChange={setRowSelection}
      onColumnVisibilityChange={setColumnVisibility}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{
        sx: { height: '55vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', maxWidth: '95vw' },
      }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      initialState={{
        density: 'compact',
        sorting: [
          { id: 'n_orden', desc: false },
          { id: 'prioridad_de_descarga', desc: false },
        ],
        grouping: ['n_orden'],
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      enableGrouping
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      // --------------- RENDER FOOTER TABLE BUTTONS -----------------------
      renderBottomToolbarCustomActions={() => (
        <div>
          <div className={styles.wrapperButtons}>
            <Tooltip title='Cancelar Ordenes' arrow placement='top'>
              <Button onClick={() => setOpen(true)} variant='contained' color='error' startIcon={<Cancel />}>
                Cancelar
              </Button>
            </Tooltip>
            <div style={{ fontWeight: '300', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <InfoOutlined />
              Pallets en Orden: {data?.length}
            </div>
          </div>
          <ModalConfirm
            open={open}
            setOpen={setOpen}
            confirm={hadleChangeState}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            title='Cancelar descargas seleccionadas'
          >
            Desea cancelar las descargas seleccionadas?. Solo se cancelaran los pallets que se encuentren en estado "en
            cola" en caso de existir uno en ejecución "transito" va a descargase normalmente hasta finalizar la
            operación.
          </ModalConfirm>
        </div>
      )}
      renderTopToolbarCustomActions={({ table }) => {
        const filters = table?.getAllColumns()?.reduce((acc,elm) => elm.getFilterValue() ? ({...acc, [elm.id]: elm.getFilterValue()}) : acc,{});
        return <ExportButtons filter={filters} data={table.getFilteredRowModel().rows} title='Confirmadas' />
      }}
      // --------- ************* ----------------
    />
  )
}

export default ConfirmadasTable
