export const texts = {
  // PORTUGUES
  /* PT: {
        MENU_GRILLA: "Quadro de operações",
        MENU_DASH: "Dashboard",
        MENU_ADM: "Gerente de paletes",
        MENU_ADM_SKU: "Gerente de SKU",


        // --------- GRILLA ----------------
        TITLE_INFO: "Informações do Palete",
        OFFC_INFO_P: "Posição",
        OFFC_INFO_P_O: "Ocupado",
        OFFC_INFO_P_L: "Livre",
        OFFC_INFO_P_B: "Bloqueado",
        OFFC_INFO_P_I: "Desabilitado",

        OFFC_INFO_E: "Estado",
        OFFC_INFO_TP: "Tipo de Posição",

        OFFC_INFO_BUTTON_ING: "Inserir Palete",
        OFFC_INFO_BUTTON_REING: "Reinserir Palete",

        DATA_F_P: "Posição",
        DATA_F_S: "Status",
        DATA_F_L: "LPN",
        DATA_F_SK: "SKU",
        DATA_F_D: "Descrição",
        DATA_F_CM: "Cód de Mercado",
        DATA_F_DN: "Desc do Mercado",
        DATA_F_I: "Entrada",
        DATA_F_U: "Data de movimentação",
        DATA_F_PA: "Produção Antiga",
        DATA_F_PR: "Produção Recente",
        DATA_F_VA: "Vencimento Antiga",
        DATA_F_VR: "Vencimento Recente",
        DATA_F_C: "Quantidade",
        DATA_F_PT: "Peso Total",
        DATA_F_PL: "Peso Liquido",
        DATA_F_PB: "Peso Bruto",
        DATA_F_TP: "Tipo de Pallet",
        DATA_F_ID: "Id",
        DATA_F_CS: "Posição de Origem",
        DATA_F_DS: "Posição de Destino",
        DATA_F_O: "Operação",
        DATA_F_OB: "Observacion",
        DATA_F_FP: "Paleta de dátiles",



        LAT_FILTRO_T: "Filtros da Grade",
        LAT_FILTRO_ACT: "Filtros Ativados",
        LAT_FILTRO_SIN_ACT: "Nenhum Filtro Ativado",
        LAT_FILTRO_S: "Sem Filtro",
        LAT_FILTRO_P: "Produto",
        LAT_FILTRO_V: "Vencimento mais Antigo",
        LAT_FILTRO_I: "Entrada",
        LAT_FILTRO_E: "Filtro Especial",

        LAT_OPERA_T: "Operação de Troca",
        LAT_OPERA_O: "Origem",
        LAT_OPERA_D: "Destino",
        LAT_OPERA_INF_T: "Informação de Origem",
        LAT_OPERA_SELEC: "Selecione posições de origem e destino para iniciar uma operação",

        LAT_CONTROL_TITLE: "Controle de Operações",
        LAT_CONTROL_B_AUTOMA: "Automático",
        LAT_CONTROL_B_AUTON: "Autônomo",
        LAT_CONTROL_B_S: "Parar",
        LAT_CONTROL_B_E: "Executar",
        LAT_CONTROL_B_C: "Cancelar",
        LAT_CONTROL_B_R: "Redefinir",
        LAT_CONTROL_E_O: "Ocupado",
        LAT_CONTROL_E_L: "Livre",
        LAT_CONTROL_E_E: "Erro",

        LAT_CONTROL_M_AUTON: "Modo Autônomo",
        LAT_CONTROL_M_C: "Carga",
        LAT_CONTROL_M_TEXT: "Ativar o modo autônomo de carga",

        OFFC_NOT_TITLE: "Notificações",

        BARRA_INF_C: "Rua",
        BARRA_INF_N: "Nivel",
        BARRA_INF_P: "Profundidade",

        MOD_INFO_TITLE: "Informações para o Operador",
        MOD_INFO_SUB_C: "Controles Básicos",
        MOD_INFO_TEXT_DES: "Para operar, posicione-se nas posições da grade:",
        MOD_INFO_CLICK: "Clique",
        MOD_INFO_POS_TEXT: "Exibe todas as informações da posição selecionada",
        MOD_INFO_POS_EDIT: "Exibe um campo onde você pode modificar as características da carga selecionada ou mudar sua posição.",
        MOD_INFO_POS_INTER: "Selecione posições de origem e destino para mover pallets.",
        MOD_INFO_POS_CHANGE_L: "Mude o nível exibido na tela. (1-9)",
        MOD_INFO_POS_STATUS_TITLE: "Estado do Equipamento",
        MOD_INFO_POS_STATUS_TEXT: "No painel de controle de operação localizado na parte inferior direita da tela, você pode ler o estado atual do transelevador:",
        MOD_INFO_POS_STATUS_O: "- OCUPADO",
        MOD_INFO_POS_STATUS_L: "- LIVRE",
        MOD_INFO_POS_STATUS_E: "- ERRO",
        MOD_INFO_POS_STATUS_O_TEXT: "O transelevador está executando uma tarefa.",
        MOD_INFO_POS_STATUS_L_TEXT: "O transelevador está livre para realizar uma tarefa.",
        MOD_INFO_POS_STATUS_E_TEXT: "O transelevador está em um estado de erro aguardando ser resolvido.",
        MOD_INFO_POS_FILTROS_TITLE: "Filtros da Grade",
        MOD_INFO_POS_FILTROS_TEXT: "As posições mostradas na grade podem ser filtradas usando filtros predefinidos com base nas propriedades da carga. Também é possível realizar um filtragem mais específica na guia BUSCAR, localizada no atalho à direita da tela.",

        EDIT_BOX_TITLE: "Editar Posição",
        EDIT_BOX_TAB_E: "Editar",
        EDIT_BOX_TAB_C: "Mudar Posição",
        EDIT_BOX_EDIT_T: "Editar Pallet",
        EDIT_BOX_EDIT_TIPE: "Tipo de Pallet",
        EDIT_BOX_EDIT_TIPE_B: "Baixo",
        EDIT_BOX_EDIT_TIPE_A: "Alto",
        EDIT_BOX_EDIT_G: "Salvar",
        EDIT_BOX_EDIT_E: "Excluir",
        EDIT_BOX_CAMBIAR_P: "Posição Atual",
        EDIT_BOX_CAMBIAR_N: "Nova Posição",
        EDIT_BOX_CAMBIAR_E: "Estado da Posição",
        EDIT_BOX_CAMBIAR_SELECT: "Selecione uma opção",

        EDIT_BOX_ERROR_1: "Erro: O pallet que você está tentando adicionar já tem uma posição atribuída",
        EDIT_BOX_ERROR_2: "Não foi possível obter dados do banco de dados, falha na atualização",
        EDIT_BOX_ERROR_3: "Erro: Não é possível excluir esta posição, LPN não disponível",
        // --------- END GRILLA ----------------

        // --------- DASHBOARD -----------------

        TAB_CAP: "Capacidade",
        TAB_MOV: "Movimentos",

        TITLE_CAP: "Painel de controle de disponibilidade",
        SUBT_CAP: "Capacidade de armazenamento em tempo real",

        STAT_BOX_D: "Espaço disponível",
        H_STAT_BOX_D: "Proporção de posições livres e totais.",

        STAT_BOX_O: "Espacio Ocupado",
        H_STAT_BOX_O: "Proporção entre as posições ocupadas e o total de posições.",

        STAT_BOX_I: "Espaço desativado",
        H_STAT_BOX_I: "Relação entre posições desativado e totais.",

        PRO_BOX_TITLE: "Numero do SKU",
        H_PRO_BOX_TITLE: "Número total de produtos diferentes no depósito",

        TITLE_GRAP_C_F: "Capacidade do Armazém",
        SUBT_GRAP_C_F: "Exibição da posição completa",

        TITLE_GRAP_C_L: "Capacidade do Armazém por Nível",
        SUBT_GRAP_C_L: "Status da posição por nível",

        TITLE_PROD_TABLE: "Produtos",
        SUBT_PROD_TABLE: "Quantidade total de produtos no depósito",

        TABLE_PROD_COLUMN_S: "Sku",
        TABLE_PROD_COLUMN_P: "Produto",
        TABLE_PROD_COLUMN_N: "Número de paletes",
        TABLE_PROD_COLUMN_U: "Stock Total",

        TITLE_MOV: "Painel de controle movimentos",
        SUBT_MOV: "Todos os movimentos de entrada, saída e realocação",

        TITLE_ACTIV_H: "Atividade de última hora",
        H_TITLE_ACTIV_H: "Número de movimentos na última hora",

        TITLE_ACTIV_24: "Actividade de última 24 horas",
        H_TITLE_ACTIV_24: "Número de movimentos nas últimas 24 horas",


        TITLE_ACTIV_W: "Atividade nos últimos 7 dias",
        H_TITLE_ACTIV_W: "Número de movimentos nos últimos 7 dias",

        TITLE_ACTIV_M: "Atividade do Último Mês",
        H_TITLE_ACTIV_M: "Número de movimentos nos últimos 30 dias",

        ICON_TEXTS_E: "Entradas",
        ICON_TEXTS_S: "Saídas",
        ICON_TEXTS_R: "Reubic.",
        ICON_TEXTS_RJ: "Rejeições",

        TITLE_MOV_TABLE: "Rastreamento de operações de paletes",
        SUBT_MOV_TABLE: "Controle de todos os movimentos por paletes, entradas, saídas, realocações e rejeições.",

        TITLE_GRAP_M_M: "Registro de Movimentos Mensais",
        SUBT_GRAP_M_M: "Evolução mensal dos movimentos de entrada, saída e realocação",

        TITLE_GRAP_M_D: "Registro de Movimentos Diários",
        SUBT_GRAP_M_D: "Movimentos diários de entrada, saída e realocação ao longo de 7 dias",

        TITLE_GRAP_M_H: "Registro de Movimentos por Hora",
        SUBT_GRAP_M_H: "Análise horária dos movimentos nas últimas 24 horas",

        // --------- END DASHBOARD -----------------

        // ----------------------- ADMIN SKU --------------------
        COLUM_LABEL: "Descrição",
        COLUM_SKU: "SKU",
        ERROR_UPDATE: "Erro de atualização",
        // ----------------------- END ADMIN SKU -------------------

        // -------------------- MACHINE LOGS TABLE ---------------
        COLUM_USER: "Usuário",
        COLUM_MACHINE: "Máquina",
        COLUMN_CODE: "Código",
        COLUMN_TYPE: "Tipo",
        COLUMN_TIME: "Data e Hora"
    }, */

  // INGLES
  ENG: {
    MENU_GRILLA: "Operation",
    MENU_DASH: "Dashboard",
    MENU_ADM: "Admin Pallets",
    MENU_ADM_SKU: "Admin SKU",

    // --------- GRILLA ----------------
    TITLE_INFO: "Pallet Information",
    OFFC_INFO_P: "Position",
    OFFC_INFO_P_O: "Occupied",
    OFFC_INFO_P_L: "Free",
    OFFC_INFO_P_B: "Blocked",
    OFFC_INFO_P_I: "Disabled",

    OFFC_INFO_E: "Status",
    OFFC_INFO_TP: "Position Type",

    OFFC_INFO_BUTTON_ING: "Enter Pallet",
    OFFC_INFO_BUTTON_REING: "Re-enter Pallet",

    DATA_F_P: "Position",
    DATA_F_S: "Status",
    DATA_F_L: "LPN",
    DATA_F_SK: "SKU",
    DATA_F_D: "Description",
    DATA_F_CM: "Market Code",
    DATA_F_DN: "Market Description",
    DATA_F_I: "Entry",
    DATA_F_U: "Movement Date",

    DATA_F_PA: "Old Production",
    DATA_F_PR: "Recent Production",
    DATA_F_VA: "Old Expiry",
    DATA_F_VR: "Recent Expiry",
    DATA_F_C: "Quantity",
    DATA_F_PT: "Total Weight",
    DATA_F_PL: "Net Weight",
    DATA_F_PB: "Gross Weight",
    DATA_F_TP: "Pallet Type",
    DATA_F_ID: "Id",
    DATA_F_CS: "Source Position",
    DATA_F_DS: "Destination Position",
    DATA_F_O: "Operation",
    DATA_F_OB: "Observation",
    DATA_F_FP: "Pallet Date",

    LAT_FILTRO_T: "Grid Filters",
    LAT_FILTRO_ACT: "Filters Activated",
    LAT_FILTRO_SIN_ACT: "No Filters Activated",
    LAT_FILTRO_S: "No Filter",
    LAT_FILTRO_P: "Product",
    LAT_FILTRO_V: "Oldest Expiry",
    LAT_FILTRO_I: "Entry",
    LAT_FILTRO_E: "Special Filter",

    LAT_OPERA_T: "Exchange Operation",
    LAT_OPERA_O: "Origin",
    LAT_OPERA_D: "Destination",
    LAT_OPERA_INF_T: "Origin Information",
    LAT_OPERA_SELEC:
      "Select origin and destination positions to initiate an operation",

    LAT_CONTROL_TITLE: "Operations Control",
    LAT_CONTROL_B_AUTOMA: "Automatic",
    LAT_CONTROL_B_AUTON: "Autonomous",
    LAT_CONTROL_B_S: "Stop",
    LAT_CONTROL_B_E: "Execute",
    LAT_CONTROL_B_C: "Cancel",
    LAT_CONTROL_B_R: "Reset",
    LAT_CONTROL_E_O: "Busy",
    LAT_CONTROL_E_L: "Free",
    LAT_CONTROL_E_E: "Error",

    LAT_CONTROL_M_AUTON: "Autonomous Mode",
    LAT_CONTROL_M_C: "Load",
    LAT_CONTROL_M_TEXT: "Activate autonomous load mode",

    OFFC_NOT_TITLE: "Notifications",

    BARRA_INF_C: "Street",
    BARRA_INF_N: "Level",
    BARRA_INF_P: "Depth",

    MOD_INFO_TITLE: "Information for the Operator",
    MOD_INFO_SUB_C: "Basic Controls",
    MOD_INFO_TEXT_DES: "To operate, position yourself on the grid positions:",
    MOD_INFO_CLICK: "Click",
    MOD_INFO_POS_TEXT: "Displays all information for the selected position",
    MOD_INFO_POS_EDIT:
      "Displays a field where you can modify the characteristics of the selected load or change its position.",
    MOD_INFO_POS_INTER:
      "Select source and destination positions to move pallets.",
    MOD_INFO_POS_CHANGE_L: "Change the displayed level on the screen. (1-9)",
    MOD_INFO_POS_STATUS_TITLE: "Equipment Status",
    MOD_INFO_POS_STATUS_TEXT:
      "In the operating control panel located in the bottom right of the screen, you can read the current status of the stacker crane:",
    MOD_INFO_POS_STATUS_O: "- OCCUPIED",
    MOD_INFO_POS_STATUS_L: "- FREE",
    MOD_INFO_POS_STATUS_E: "- ERROR",
    MOD_INFO_POS_STATUS_O_TEXT: "The stacker crane is executing a task.",
    MOD_INFO_POS_STATUS_L_TEXT: "The stacker crane is free to perform a task.",
    MOD_INFO_POS_STATUS_E_TEXT:
      "The stacker crane is in an error state waiting to be resolved.",
    MOD_INFO_POS_FILTROS_TITLE: "Grid Filters",
    MOD_INFO_POS_FILTROS_TEXT:
      "The positions shown in the grid can be filtered using predefined filters based on load properties. More specific filtering can also be done from the SEARCH tab, located in the shortcut on the right side of the screen.",

    // MODAL INFORMACION SIMULACION
    MODAL_TITLE: "Simulator instructions",
    MODAL_CHARGUE_MODE_TITLE: "Activate the load mode of the stacker crane",
    MODAL_CHARGUE_MODE_INSTRUCTION_ONE:
      "Go to Operations Control and press the AUTONOMOUS button.",
    MODAL_CHARGUE_MODE_INSTRUCTION_TWO:
      "In the options that appear, click on the UPLOAD button.",
    MODAL_DOWNLOAD_MODE_TITLE:
      "Activate the unloading mode of the stacker crane.",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE:
      "Access the available pallets using the SEARCH button.",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO:
      "Once in the list, select the pallets to be unloaded and press DOWNLOAD LIST",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE:
      "Go to the Download Orders list in the Menu (upper right corner of the screen).",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR:
      "Select the pallets you previously sent to the list to confirm their unloading.",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE:
      "Select from the list the downloads to be performed and press the CONFIRM button.",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX:
      "Click on the AUTONOMOUS button in Operations Control and select the Download option.",

    EDIT_BOX_TITLE: "Edit Position",
    EDIT_BOX_TAB_E: "Edit",
    EDIT_BOX_TAB_C: "Change Position",
    EDIT_BOX_TAB_E_POS: "Edit Position",

    EDIT_BOX_EDIT_T: "Edit Pallet",
    EDIT_BOX_EDIT_TIPE: "Pallet Type",
    EDIT_BOX_EDIT_TIPE_B: "Low",
    EDIT_BOX_EDIT_TIPE_A: "High",
    EDIT_BOX_EDIT_G: "Save",
    EDIT_BOX_EDIT_E: "Delete",
    EDIT_BOX_CAMBIAR_P: "Current Position",
    EDIT_BOX_CAMBIAR_N: "New Position",
    EDIT_BOX_CAMBIAR_E: "Position Status",
    EDIT_BOX_CAMBIAR_SELECT: "Select an option",

    EDIT_BOX_ERROR_1:
      "Error: The pallet you are trying to add already has an assigned position",
    EDIT_BOX_ERROR_2:
      "Could not retrieve data from the database, update failed",
    EDIT_BOX_ERROR_3: "Error: Cannot delete this position, LPN not available",

    // --------- END GRILLA -----------

    // --------- DASHBOARD -----------------

    TAB_CAP: "Capability",
    TAB_MOV: "Movements",

    TITLE_CAP: "Availability control panel",
    SUBT_CAP: "Know the storage capacity of the warehouse in real time",

    STAT_BOX_O: "Space Occupied",
    H_STAT_BOX_O:
      "Ratio between occupied and total positions in the warehouse.",

    STAT_BOX_D: "Space Available",
    H_STAT_BOX_D: "Ratio between free and total positions in the warehouse",

    STAT_BOX_I: "Space Disabled",
    H_STAT_BOX_I:
      "Ratio between disqualified and total positions in the warehouse.",

    PRO_BOX_TITLE: "Quantity of product types",
    H_PRO_BOX_TITLE: "Total quantity of different products in the warehouse",

    TITLE_GRAP_C_F: "Warehouse capacity",
    SUBT_GRAP_C_F: "Full position display",

    TITLE_GRAP_C_L: "Warehouse capacity per level",
    SUBT_GRAP_C_L: "Position status by level",

    TITLE_PROD_TABLE: "Products",
    SUBT_PROD_TABLE: "Total quantity of products in the warehouse",

    TABLE_PROD_COLUMN_S: "Sku",
    TABLE_PROD_COLUMN_P: "Product",
    TABLE_PROD_COLUMN_N: "Qty of pallets",
    TABLE_PROD_COLUMN_U: "Stock Total",

    TITLE_MOV: "Movement control panel",
    SUBT_MOV: "Analysis of entries, exits and relocations in the warehouse",

    TITLE_ACTIV_H: "Latest hour activity",
    H_TITLE_ACTIV_H: "All movements in latest hour",

    TITLE_ACTIV_24: "Activity last 24 hours",
    H_TITLE_ACTIV_24: "Number of movements in the last 24 hours",

    TITLE_ACTIV_W: "Activity last 7 days",
    H_TITLE_ACTIV_W: "Number of movements in the last 7 days",

    TITLE_ACTIV_M: "Activity Last Month",
    H_TITLE_ACTIV_M: "Number of movements in the last 30 days",

    ICON_TEXTS_E: "Entries",
    ICON_TEXTS_S: "Exits",
    ICON_TEXTS_R: "Relocation",
    ICON_TEXTS_RJ: "Rejections",

    TITLE_MOV_TABLE: "Operations tracking by pallet",
    SUBT_MOV_TABLE: "Movement control by pallets",

    TITLE_GRAP_M_M: "Monthly Movement Record",
    SUBT_GRAP_M_M:
      "Monthly evolution of incoming, outgoing, and relocation movements",

    TITLE_GRAP_M_D: "Daily Movement Record",
    SUBT_GRAP_M_D:
      "Daily incoming, outgoing, and relocation movements over 7 days",

    TITLE_GRAP_M_H: "Hourly Movement Record",
    SUBT_GRAP_M_H: "Hourly analysis of movements in the last 24 hours",

    // ---------END DASHBOARD -----------------

    // ----------------------- ADMIN SKU --------------------
    COLUM_LABEL: "Description",
    COLUM_SKU: "SKU",
    ERROR_UPDATE: "Error updating ",
    // ----------------------- END ADMIN SKU -------------------

    // -------------------- MACHINE LOGS TABLE ---------------
    COLUMN_USER: "User",
    COLUMN_MACHINE: "Machine",
    COLUMN_CODE: "Code",
    COLUMN_TYPE: "Type",
    COLUMN_TIME: "Date and Time",
  },

  //ESPAÑOL

  ES: {
    MENU_GRILLA: "Grilla Operación",
    MENU_DASH: "Dashboard",
    MENU_ADM: "Administrador de Pallets",
    MENU_ADM_SKU: "Admin SKU",
    // --------- GRILLA ---------------

    TITLE_INFO: "Información del Pallet",
    OFFC_INFO_P: "Posición",
    OFFC_INFO_P_O: "Ocupado",
    OFFC_INFO_P_L: "Libre",
    OFFC_INFO_P_B: "Bloqueado",
    OFFC_INFO_P_I: "Inhabilitado",

    OFFC_INFO_E: "Estado",
    OFFC_INFO_TP: "Tipo de Posición",

    OFFC_INFO_BUTTON_ING: "Ingresar Pallet",
    OFFC_INFO_BUTTON_REING: "Reingresar Pallet",

    DATA_F_P: "Posición",
    DATA_F_S: "Status",
    DATA_F_L: "LPN",
    DATA_F_SK: "SKU",
    DATA_F_D: "Descripción",
    DATA_F_CM: "Cod de Mercado",
    DATA_F_DN: "Desc de Mercado",
    DATA_F_I: "Ingreso",
    DATA_F_U: "Fecha Movimiento",
    DATA_F_PA: "Producción Antigua",
    DATA_F_PR: "Producción Reciente",
    DATA_F_VA: "Vencimiento",
    DATA_F_VR: "Vencimiento Reciente",
    DATA_F_C: "Cantidad",
    DATA_F_PT: "Peso Total",
    DATA_F_PL: "Peso Liquido",
    DATA_F_PB: "Peso Bruto",
    DATA_F_TP: "Tipo de Pallet",
    DATA_F_ID: "Id",
    DATA_F_CS: "Posición de origen",
    DATA_F_DS: "Posición de Destino",
    DATA_F_O: "Operación",
    DATA_F_OB: "Observacion",
    DATA_F_FP: "Fecha de Pallet",

    LAT_FILTRO_T: "Filtros de grilla",
    LAT_FILTRO_ACT: "Filtros activado",
    LAT_FILTRO_SIN_ACT: "Ningun filtro activado",
    LAT_FILTRO_S: "Sin Filtro",
    LAT_FILTRO_P: "SKU",
    LAT_FILTRO_V: "Vencimiento",
    LAT_FILTRO_I: "Ingreso",
    LAT_FILTRO_E: "Filtro Especial",

    LAT_OPERA_T: "Operación de Intercambio",
    LAT_OPERA_O: "Origen",
    LAT_OPERA_D: "Destino",
    LAT_OPERA_INF_T: "Información de origen",
    LAT_OPERA_SELEC:
      "Seleccione las posiciones de origen y destino para iniciar una operación",

    LAT_CONTROL_TITLE: "Control de Operaciones",
    LAT_CONTROL_B_AUTOMA: "Automatico",
    LAT_CONTROL_B_AUTON: "Autonomo",
    LAT_CONTROL_B_S: "Stop",
    LAT_CONTROL_B_E: "Ejecutar",
    LAT_CONTROL_B_C: "Cancelar",
    LAT_CONTROL_B_R: "Reset",
    LAT_CONTROL_E_O: "Ocupado",
    LAT_CONTROL_E_L: "Libre",
    LAT_CONTROL_E_E: "Error",

    LAT_CONTROL_M_AUTON: "Modo Autonomo",
    LAT_CONTROL_M_C: "Carga",
    LAT_CONTROL_M_TEXT: "Activar el modo autonomo Carga o Descarga",

    OFFC_NOT_TITLE: "Notificaciones",

    BARRA_INF_C: "Calle",
    BARRA_INF_N: "Nivel",
    BARRA_INF_P: "Profundidad",

    // MODAL INFORMACION OPERADOR
    MOD_INFO_TITLE: "Información para el operador",
    MOD_INFO_SUB_C: "Controles básicos",
    MOD_INFO_TEXT_DES: "Para operar, sitúese en las posiciones de la grilla: ",
    MOD_INFO_CLICK: "Clic",
    MOD_INFO_POS_TEXT:
      "Muestra toda la información de la posición seleccionada",
    MOD_INFO_POS_EDIT:
      "Muestra un campo en el que puede modificar las características de la carga seleccionada o cambiar su posición.",
    MOD_INFO_POS_INTER:
      "Seleccione una posicion de origen y destino para mover pallet.",
    MOD_INFO_POS_CHANGE_L: "Cambie el nivel de visualizado en pantalla. (1-9)",
    MOD_INFO_POS_STATUS_TITLE: "Estado del equipo",
    MOD_INFO_POS_STATUS_TEXT:
      "En el panel de control de funcionamiento situado en la parte inferior derecha de la pantalla se puede leer el estado actual del transelevador:",
    MOD_INFO_POS_STATUS_O: "- OCUPADO",
    MOD_INFO_POS_STATUS_L: "- LIBRE",
    MOD_INFO_POS_STATUS_E: "- ERROR",
    MOD_INFO_POS_STATUS_O_TEXT: "El transelevador esta ejecutando una tarea.",
    MOD_INFO_POS_STATUS_L_TEXT:
      "El transelevador esta libre para realizar una tarea.",
    MOD_INFO_POS_STATUS_E_TEXT:
      "El transelevador esta se encuentra en error en espera de ser resuelto.",
    MOD_INFO_POS_FILTROS_TITLE: "Filtros de Grilla",
    MOD_INFO_POS_FILTROS_TEXT:
      "Las posiciones mostradas en la Grilla pueden discriminarse mediante filtros predefinidos basados en las propiedades de la carga. También se puede realizar un filtrado más específico desde la pestaña BUSCAR, situada en el acceso directo de la parte derecha de la pantalla",
    // MODAL INFORMACION SIMULACION
    MODAL_TITLE: "Instrucciones del simulador",
    MODAL_CHARGUE_MODE_TITLE: "Accionar el modo carga del transelevador",
    MODAL_CHARGUE_MODE_INSTRUCTION_ONE:
      "Diríjase al Control de Operaciones y oprima el botón AUTÓNOMO",
    MODAL_CHARGUE_MODE_INSTRUCTION_TWO:
      "En las opciones que se despliegan, oprima el botón CARGA",
    MODAL_DOWNLOAD_MODE_TITLE: "Accionar el modo de descarga del transelevador",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE:
      "Acceda a los pallets disponibles por medio del botón BÚSQUEDA",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO:
      "Una vez en la lista, seleccione los pallets a descargar y oprima LISTA DE DESCARGA",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE:
      "Diríjase a la lista Órdenes de Descarga en el Menú (Esquina superior derecha de la pantalla)",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR:
      "Seleccione los pallets que envió previamente a la lista para confirmar su descarga",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE:
      "Seleccione de la lista las descargas que realizará y oprima el botón CONFIRMAR",
    MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX:
      "Oprima el botón AUTÓNOMO en Control de Operaciones y seleccione la opción Descarga",

    EDIT_BOX_TITLE: "Editar Posición",
    EDIT_BOX_TAB_E: "Editar",
    EDIT_BOX_TAB_C: "Cambiar Posición",
    EDIT_BOX_TAB_E_POS: "Editar Estado",
    EDIT_BOX_EDIT_T: "Editar Pallet",
    EDIT_BOX_EDIT_TIPE: "Tipo de Pallet",
    EDIT_BOX_EDIT_TIPE_B: "Bajo",
    EDIT_BOX_EDIT_TIPE_A: "Alto",
    EDIT_BOX_EDIT_G: "Guardar",
    EDIT_BOX_EDIT_E: "Eliminar",
    EDIT_BOX_CAMBIAR_P: "Posición Actual",
    EDIT_BOX_CAMBIAR_N: "Posición Nueva",
    EDIT_BOX_CAMBIAR_E: "Estado de Posición",
    EDIT_BOX_CAMBIAR_SELECT: "Seleccione una opción",

    EDIT_BOX_ERROR_1:
      "Error: El pallet que quiere añadir ya tiene posición asignada",
    EDIT_BOX_ERROR_2: "No se pudo obtener datos de DB, fallo actualización",
    EDIT_BOX_ERROR_3:
      "Error: No se puede eliminar esta posición LPN no disponible",

    // ---------- END GRILLA ----------

    // --------- DASHBOARD -----------------

    TAB_CAP: "Capacidad",
    TAB_MOV: "Movimientos",

    TITLE_CAP: "Capacidad del Deposito",
    SUBT_CAP: "Conozca la capacidad del deposito en tiempo real",

    STAT_BOX_O: "Espacio Ocupado",
    H_STAT_BOX_O: "Relación entre espacio ocupado y total de posiciones.",

    STAT_BOX_D: "Espacio Disponible",
    H_STAT_BOX_D: "Relación entre espacio disponible y total de posiciones.",

    STAT_BOX_I: "Espacio Inhabilitado",
    H_STAT_BOX_I:
      "Relación entre espacio inahabilidato y el total de las posiciones.",

    PRO_BOX_TITLE: "Cantidad de SKU",
    H_PRO_BOX_TITLE: "Cantidad de SKU en deposito.",

    TITLE_GRAP_C_F: "Capacidad del deposito",
    SUBT_GRAP_C_F: "Visualización de todas las posiciones",

    TITLE_GRAP_C_L: "Capacidad del deposito por Nivel",
    SUBT_GRAP_C_L: "Visualización de las posiciones por nivel",

    TITLE_PROD_TABLE: "Productos",
    SUBT_PROD_TABLE: "Cantidad total de tipos de producto en el desposito",

    TABLE_PROD_COLUMN_S: "Sku",
    TABLE_PROD_COLUMN_P: "Producto",
    TABLE_PROD_COLUMN_N: "Número de pallets",
    TABLE_PROD_COLUMN_U: "Stock Total",

    TITLE_MOV: "Panel de control movimientos",
    SUBT_MOV: "Análisis de Entradas, Salidas y Reubicaciones en el depósito",

    TITLE_ACTIV_H: "Actividad última hora",
    H_TITLE_ACTIV_H: "Cantidad de movimientos en la última hora",

    TITLE_ACTIV_24: "Actividad últimas 24 horas",
    H_TITLE_ACTIV_24: "Cantidad de movimientos en las últimas 24 horas",

    TITLE_ACTIV_W: "Actividad últimos 7 días",
    H_TITLE_ACTIV_W: "Cantidad de movimientos en los últimos 7 días",

    TITLE_ACTIV_M: "Actividad último mes",
    H_TITLE_ACTIV_M: "Cantidad de movimientos en los últimos 30 días",

    ICON_TEXTS_E: "Entradas",
    ICON_TEXTS_S: "Salidas",
    ICON_TEXTS_R: "Reubic.",
    ICON_TEXTS_RJ: "Rechazos",

    TITLE_MOV_TABLE: "Seguimiento de Operaciones por pallets",
    SUBT_MOV_TABLE:
      "Control de todos los movimientos por Pallets, ingresos, salidas, reubicaciones, rechazos",

    TITLE_GRAP_M_M: "Registro de movimientos mensuales",
    SUBT_GRAP_M_M:
      "Evolución mensual de los movimientos de entrada, salida y reubicación",

    TITLE_GRAP_M_D: "Registro de movimientos diarios",
    SUBT_GRAP_M_D:
      "Movimientos diarios de entrada, salida y reubicación en 7 días",

    TITLE_GRAP_M_H: "Registro de movimientos por hora",
    SUBT_GRAP_M_H: "Análisis horario de movimientos en las últimas 24 horas",

    // ---------END DASHBOARD -----------------

    // ----------------------- ADMIN SKU --------------------
    COLUM_LABEL: "Descripción",
    COLUM_SKU: "SKU",
    ERROR_UPDATE: "Error al actualizar ",
    // ----------------------- END ADMIN SKU --------------------

    // -------------------- MACHINE LOGS TABLE ---------------
    COLUMN_USER: "Usuario",
    COLUMN_MACHINE: "Maquina",
    COLUMN_CODE: "Codigo",
    COLUMN_TYPE: "Tipo",
    COLUMN_TIME: "Fecha y hora",
  },
};
