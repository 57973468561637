import styles from './Navbar.module.css'
import { ReactComponent as Ctto } from '././assets/Ctto_LOGO-WHITE.svg'

const Navbar = () => {
  return (
    <div>
      <div className={styles.sectionName}> Almacen </div>

      <div className={styles.Navbar}>
        <Ctto className={styles.logo} />
      </div>
    </div>
  )
}

export default Navbar
