import { Circle } from '@mui/icons-material'
import React, { createContext } from 'react'
import { useContext } from 'react'
import { texts } from '../../i18n'
import { ContextoAlmacen } from '../Almacen/AlmacenContext'
export const ContextoData = createContext()

export const DataContextProvider = ({ children }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  // Colors Table
  const colors = {
    completado: '#039538',
    transito: '#f87928',
    'en cola': '#607d8b',
    cancelado: '#73797e',
    pendiente: '#d9d20c',
  }

  const STATE = {
    // ----------------- FORMATO DE DATOS TABLA BUSQUEDA E INFO BOX -----------------

    data_format: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
      },
      // {
      //   accessorKey: 'status',
      //   header: texts[lang]['DATA_F_S'],
      // },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA MOVIMIENTOS -----------------

    data_format_movements: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_ID'],
      },
      {
        accessorKey: 'operation',
        header: texts[lang]['DATA_F_O'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'createdAt',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'obs',
        header: 'Observaciones',
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA DESCARGA -----------------

    data_format_descarga: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
      },
      {
        accessorKey: 'status',
        header: texts[lang]['DATA_F_S'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA DESCARGA CONFIRMADA -----------------

    data_format_descarga_confirm: [
      {
        accessorKey: 'n_orden',
        header: 'N° de Orden',
        maxSize: 120,
      },
      {
        accessorKey: 'estado',
        header: 'Estado',
        Cell: ({ cell }) => (
          <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
            <Circle sx={{ color: colors[cell.getValue()] }} />
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'data_origin',
        header: 'Origen',
        maxSize: 100,
      },
      {
        accessorKey: 'prioridad_de_descarga',
        header: 'Prioridad',
        maxSize: 100,
      },
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],

    // ----------------- FORMATO DE DATOS TABLA HISTORICO DESCARGA -----------------

    data_format_descarga_history: [
      {
        accessorKey: 'n_orden',
        header: 'N° de Orden',
        maxSize: 120,
      },
      {
        accessorKey: 'estado',
        header: 'Estado',
        Cell: ({ cell }) => (
          <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
            <Circle sx={{ color: colors[cell.getValue()] }} />
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'data_origin',
        header: 'Origen',
        maxSize: 100,
      },
      {
        accessorKey: 'prioridad_de_descarga',
        header: 'Prioridad',
        maxSize: 100,
      },
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],
    // -------------------- FORMATO DE DATOS TABLA PALLETS ADMINISTRADOR DE POSICIONES -------------
    data_format_pallets: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
        Cell: ({ cell }) => cell.getValue() ?? 'sin informacion',
      },
      {
        accessorKey: 'estado',
        header: texts[lang]['DATA_F_S'],
        Cell: ({ cell }) => cell.getValue() ?? 'sin informacion',
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'family',
        header: 'Familia'
      },
      {
        accessorKey: 'countryOfDest',
        header: 'Destino'
      },
      {
        accessorKey: 'dateFrom',
        header: 'Fecha Desde'
      },
      {
        accessorKey: 'dateUntil',
        header: 'Fecha Hasta'
      },
      {
        accessorKey: 'weight',
        header: 'KG Pallet'
      },
      {
        accessorKey: 'boxQty',
        header: 'Cajas'
      },
    ],

    data_format_SKU: [
      {
        accessorKey: 'description',
        header: texts[lang]['COLUM_LABEL'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['COLUM_SKU'],
      },
    ],
  }

  return <ContextoData.Provider value={STATE}>{children}</ContextoData.Provider>
}

export default DataContextProvider
