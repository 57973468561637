import React, { useContext, useEffect, useRef } from 'react'
import Grilla from './components/Grilla/Grilla'
import styles from './Almacen.module.css'
import PanelControl from './components/PanelControl/PanelControl'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import BarraInferior from './components/BarraInferior/BarraInferior'
import useSocket from '../../custom-hooks/useSocket'
import FloatInformation from './components/FloatInformation/FloatInformation'
import { useDispatch, useSelector } from 'react-redux'
import { zoomIn, zoomOut } from './redux/slices/gridView/gridViewSlice'

const Almacen = ({ firstRender }) => {
  const dispatch = useDispatch()

  const { warehouse } = useContext(ContextoAlmacen)
  const socket = useSocket()
  const nivel_actual = useSelector((state) => state.gridView.actualLevel)
  const grillaRef = useRef(null)
  //Variables de desplazamiento y zoom
  let isDragging = false;
  let startX;
  let startY;


  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    } else {
      socket.emit('action:actualizar-socket')
    }
    return () => socket.off('action:actualizar-socket')
    // eslint-disable-next-line
  }, [socket])

  //Funciones de desplazamiento y zoom
  const handleWheel = (e) => {
    if (e.shiftKey) {
      if (e.deltaY < 0) {
        dispatch(zoomIn(0.1));
      } else if (e.deltaY > 0) {
        dispatch(zoomOut(0.1));
      }
    }
  };
  
  const handleMouseDown = (e) => {
    isDragging = true;
    startX = e.clientX;
    startY = e.clientY;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - startX;
    const dy = e.clientY - startY;
    grillaRef.current.scrollLeft -= dx;
    grillaRef.current.scrollTop -= dy;
    startX = e.clientX;
    startY = e.clientY;
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  return (
    <div className={styles.container} onMouseUp={handleMouseUp}>
      <div
        ref={grillaRef}
        className={`${styles.grilla} ${
          warehouse.dark ? styles.grillaDark : ""
        }`}
        onWheel={handleWheel}
        style={{ overflow: "auto" }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <FloatInformation refGrilla={grillaRef}>
          <h5>Nivel {nivel_actual}</h5>
        </FloatInformation>
        <Grilla />
      </div>

      <div
        className={`${styles.barraInf} ${
          warehouse.dark && styles.barraInfDark
        }`}
      >
        <BarraInferior />
      </div>

      <div className={!warehouse.dark ? styles.panel : styles.panelDark}>
        <PanelControl />
      </div>
    </div>
  );
}

export default Almacen
