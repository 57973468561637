import React from 'react'
import { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import styles from './OffCanvasPalletIngreso.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Circle, DownloadingOutlined } from '@mui/icons-material'
import MaterialReactTable from 'material-react-table'
import { translate_table } from '../Busqueda/Translate'
import { useEffect } from 'react'
import { getConfirmOrdersThunk } from '../../../Descarga/redux/slices/lista/thunk/getConfirmOrdersThunk'

const OffCanvasDescargas = ({ visible, setVisible }) => {
  const data = useSelector((state) => state.lista_descarga.confirm_orders.lista).filter(
    (l) => l?.estado !== 'pendiente'
  )
  const { warehouse } = useContext(ContextoAlmacen)
  const dispatch = useDispatch()
  // Colors Table
  const colors = {
    completado: '#039538',
    transito: '#f87928',
    'en cola': '#607d8b',
    cancelado: '#73797e',
  }

  useEffect(() => {
    dispatch(getConfirmOrdersThunk()) //eslint-disable-next-line
  }, [])

  const columns = [
    {
      accessorKey: 'estado',
      header: 'Estado',
      Cell: ({ cell }) => (
        <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
          <Circle sx={{ color: colors[cell.getValue()] }} />
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: 'posicion',
      header: 'Posición',
    },
    {
      accessorKey: 'lpn',
      header: 'LPN',
    },
    {
      accessorKey: 'sku',
      header: 'SKU',
    },
    {
      accessorKey: 'producto',
      header: 'Producto',
    },
  ]

  return (
    <Offcanvas
      enforceFocus={false}
      show={visible}
      backdrop={false}
      onHide={() => setVisible(false)}
      placement='end'
      className={`${styles.canvasDescarga} ${warehouse.dark && styles.canvasDescargaDark}`}
    >
      <Offcanvas.Header closeButton closeVariant={warehouse.dark ? 'white' : 'black'}>
        <Offcanvas.Title>
          <div className={styles.wrapperTitle}>
            <DownloadingOutlined /> Descargas Confirmadas
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.canvaBody}>
        <MaterialReactTable
          columns={columns}
          data={data || []}
          localization={translate_table}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
          muiTableContainerProps={{
            sx: { height: '60vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', maxWidth: '95vw' },
          }}
          muiToolbarAlertBannerProps={{
            sx: {
              color: warehouse?.dark === true && '#ffffff',
              backgroundColor: warehouse?.dark === true && '#356161',
            },
          }}
          initialState={{
            density: 'compact',
            pagination: {
              pageIndex: 0,
              pageSize: 50,
            },
          }}
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150, //default size is usually 180
          }}
          enableTopToolbar={false} //hide top toolbar.
          enableBottomToolbar={false} //hide bottom toolbar.
          // --------- ************* ----------------
        />
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default OffCanvasDescargas
