const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// +++++++++++++++++++++++++ UPDATE AUTONOMO REGLA   ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getAutonomoCargaConfig = async () => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/", {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json",
        Accept: "aplication/json",
      },
    });

    return await response.json();
  } catch (error) {
    throw Error("No se puede obtener la configuracion del autonomo");
  }
};

// +++++++++++++++++++++++++ POST AUTONOMO REGLA  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const postAutonomoCargaConfig = async (regla) => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/", {
      method: "POST",
      body: JSON.stringify(regla),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  } catch (e) {
    throw Error("Error no se pudo guardar la regla");
  }
};

// +++++++++++++++++++++++++ UPDATE AUTONOMO REGLA   ++++++++++++++++++++++++++++++++++++++++++++++++++
export const updateAutonomoCargaConfig = async (regla) => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/", {
      method: "PUT",
      body: JSON.stringify(regla),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error("Error al guardar autonomo");
  }
};

// +++++++++++++++++++++++++ GET AUTONOMO DESCARGA ++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const getAutonomoDescargaConfig = async () => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/download", {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json",
        Accept: "aplication/json",
      },
    });

    return await response.json();
  } catch (error) {
    throw Error("No se puede obtener la configuracion del autonomo descarga");
  }
};

// +++++++++++++++++++++++++ UPDATE AUTONOMO DESCARGA   ++++++++++++++++++++++++++++++++++++++++++++++++++
export const updateAutonomoDescargaConfig = async (regla) => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/download", {
      method: "PUT",
      body: JSON.stringify(regla),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error("Error al guardar autonomo descarga");
  }
};

// +++++++++++++++++++++++++  TRANSFER LIST   ++++++++++++++++++++++++++++++++++++++++++++++++++
export const postTransferList = async (transfer) => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/transfer", {
      method: "POST",
      body: JSON.stringify(transfer),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error("Error al generar transferencia");
  }
};

// +++++++++++++++++++++++++  GET LIST   ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getTransferList = async () => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/transfer", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error("Error al obtener transferencia");
  }
};

export const deleteItemTransfer = async (selected) => {
  try {
    const response = await fetch(SERVER_URL + "/autonomous/transfer", {
      method: "PUT",
      body: JSON.stringify(selected),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw Error("Error al obtener transferencia");
  }
};
