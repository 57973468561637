import React, { useState } from 'react'
import { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import styles from './OffCanvasTransportadores/OffCanvasTransportadores.module.css'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { useDispatch } from 'react-redux'
import { getNotificationsThunk } from '../redux/slices/notifications/thunks/notificationsThunks'
import { clearCount } from '../redux/slices/notifications/NotificationsSlice'


const OffCanvasUpdated = ({ visible, setVisible, title, icon, notif, children, optFunction = () => {} }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const [display, setDisplay] = useState('none')
  const dispatch = useDispatch()

  return (
    <Offcanvas
      enforceFocus={false}
      show={visible}
      backdrop={false}
      onHide={() => {
        optFunction()
        dispatch(clearCount())
        setVisible(false)
        setDisplay('none')
      }}
      placement='end'
      className={`${notif ? styles.canvasNot : styles.canvasDescarga} ${warehouse.dark && styles.canvasDescargaDark}`}
      onEntered={() => {
        setDisplay('')
      }}
      onShow={() => dispatch(getNotificationsThunk())}
      style={{
        display: display,
      }}
    >
      <Offcanvas.Header closeButton closeVariant={warehouse.dark ? 'white' : 'black'}>
        <Offcanvas.Title>
          <div className={styles.wrapperTitle}>
            {icon}
            {title}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.canvaBody}>
        <div style={{ width: '-webkit-fill-available' }}>{children}</div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default OffCanvasUpdated
