import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', my:"1rem" }}>
      <CircularProgress variant='determinate' color='ctto' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='caption' component='div' color='text.secondary'>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
}

const LoadingCtto = ({ children, loading, error }) => {
  const [progress, setProgress] = useState(10)
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10))
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])


  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {children}
        <CircularProgressWithLabel value={progress} size={70}/>
        {error?.message}
      </div>
    </Backdrop>
  )
}

export default LoadingCtto
