import { InfoOutlined, MyLocationOutlined } from '@mui/icons-material'
import { Skeleton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import useSocket from '../../../../custom-hooks/useSocket'
import { texts } from '../../../../i18n'
import styles from './EstadoASRS.module.css'

const EstadoASRS = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const [loading, setLoading] = useState(true)
  const [calle, setCalle] = useState('')
  const [nivel, setNivel] = useState('')
  const [profundidad, setProfundidad] = useState('')

  const [operacion, setOperacion] = useState('Equipo esperando ordenes para ejecutar')
  const socket = useSocket()

  useEffect(() => {
    socket.on('state:calle-actual', (res) => {
        setCalle(res)
    })
    socket.on('state:operacion-actual', (res) => {
      if(res) setOperacion(res)
    })
    socket.on('state:nivel-actual', (res) => {
      setNivel(res)
    })
    socket.on('state:profundidad-actual', (prof) => {
      setProfundidad(prof || 0)
    })

    return () => {
      socket.off('state:calle-actual')
      socket.off('state:operacion-actual')
      socket.off('state:nivel-actual')
    }
    // eslint-disable-next-line
  }, [])

  setTimeout(() => {
    setLoading(false)
  }, 5000)

  return (
    <>
      <div className={styles.wrapperText}>
        <span>
          <InfoOutlined /> Estado:
        </span>
        <Tooltip title={loading ? 'esperando' : operacion} arrow placement='top'>
          {loading ? <Skeleton width='10vw' /> : <p>{operacion}</p>}
        </Tooltip>
      </div>
      <div className={styles.positionActual}>
        <MyLocationOutlined />
        <div>
          <span>{texts[lang]['BARRA_INF_N']}: </span>
          {loading ? <Skeleton width='-webkit-fill-available' /> : <p>{nivel}</p>}
        </div>
        <div>
          <span>{texts[lang]['BARRA_INF_C']}:</span>
          {loading ? <Skeleton width='-webkit-fill-available' /> : <p>{calle}</p>}
        </div>
        <div>
          <span>{texts[lang]['BARRA_INF_P']}:</span>
          {loading ? <Skeleton width='-webkit-fill-available' /> : <p>{profundidad}</p>}
        </div>
      </div>
    </>
  )
}

export default EstadoASRS
