import React, { useContext, useEffect } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import styles from './BarraInferior.module.css'
import BotonDesplegable from '../BotonDesplegable/BotonDesplegable'
import EstadoASRS from '../EstadoASRS/EstadoASRS'
import { useDispatch, useSelector } from 'react-redux'
import { setLevel } from '../../redux/slices/gridView/gridViewSlice'
import useSocket from '../../../../custom-hooks/useSocket'

const BarraInferior = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const niveles = [...Array(warehouse.niveles)]
  const dispatch = useDispatch()
  const level = useSelector((state) => state.gridView.actualLevel)
  const socket = useSocket()
  const handleChange = (e) => {
    dispatch(setLevel(+e.target.value))
  }

  useEffect(() => {
    socket.emit('action:actualizar-socket') // eslint-disable-next-line
  }, [level])

  const levels = [
    { code: 'Digit1', level: 1 },
    { code: 'Digit2', level: 2 },
    { code: 'Digit3', level: 3 },
    { code: 'Digit4', level: 4 },
    { code: 'Digit5', level: 5 },
    { code: 'Digit6', level: 6 },
    { code: 'Digit7', level: 7 },
    { code: 'Digit8', level: 8 },
    { code: 'Digit9', level: 9 },
  ]

  const changeLevelKeyboard = (code) => {
    const level = levels.find((item) => item.code === code)
    if (level) {
      dispatch(setLevel(level.level))
    }
  }

  useEffect(() => {
    window.addEventListener('keypress', (e) => {
      if (e.shiftKey) {
        changeLevelKeyboard(e.code)
        return
      }
      return
    })

    return () => {
      window.removeEventListener('keypress', () => {})
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <select
        className={`${styles.select} ${warehouse.dark && styles.selectDark}`}
        onChange={handleChange}
        value={level}
      >
        {niveles.map((_, i) => (
          <option className={warehouse.dark && styles.optionDark} key={i} value={i + 1}>
            Nível {i + 1}
          </option>
        ))}
      </select>
      <EstadoASRS />
      <div>
        <BotonDesplegable />
      </div>
    </>
  )
}

export default BarraInferior
